let dataUi = {
    1: {
        eng: "Weapons obtained from this crate will have the Strange quality.",
        pol: "Bronie otrzymane z tej skrzynki będą posiadały jakość Kuriozum.",
        sch: "从这个箱子中获得的武器具有奇异品质。",
        bra: "Armas obtidas por essa caixa terão a qualidade Estranha.",
        fre: "Les armes obtenues de cette caisse seront de qualité Étrange.",
        cze: "Zbraně uvnitř mají Strange kvalitu.",
        hun: "Ebben a ládában található fegyverek Fura minőségűek lesznek.",
        rom: "Armele obtinute din aceasta cutie vor avea o calitate Stranie.",
		swe: "Vapen som erhålls från denna låda kommer att ha den Avvikande kvaliteten.",
		fil: "Ang mga armas na makukuha sa kahon ay magkakaroon ng kalidad na Strange.",
    },
    2: {
        eng: "Items obtained from this crate might have the Strange quality.",
        pol: "Przedmioty otrzymane z tej skrzynki mogą posiadać jakość Kuriozum.",
        sch: "从这个箱子中获得的物品可能具有奇异品质。",
        bra: "Itens obtidos por essa caixa podem ter a qualidade Estranha.",
        fre: "Les objets obtenus de cette caisse peuvent être de qualité Étrange.",
        cze: "Předměty získané z této krabice mohou mít Strange kvalitu.",
        hun: "Ebben a ládában található tárgyak Fura minőségűek lehetnek.",
        rom: "Obiectele obtinute din aceasta cutie pot avea o calitate Stranie.",
		swe: "Föremål som erhålls från denna låda kan ha den Avvikande kvaliteten.",
		fil: "Mga aytem na makukuha sa kahon ay maaring magkakaroon ng kalidad na Strange.",
    },
    3: {
        eng: "Items obtained from this crate will have the Haunted quality.",
        pol: "Przedmioty otrzymane z tej skrzynki będą posiadały jakość Przekleństwo.",
        sch: "从这个箱子中获得的物品具有闹鬼品质。",
        bra: "Itens obtidos por essa caixa terão a qualidade Assombrada.",
        fre: "Les objets obtenus de cette caisse seront de qualité Hantée.",
        cze: "Předměty uvnitř mají Haunted kvalitu.",
        hun: "Ebben a ládában található tárgyak Kísértetjárta minőségűek lesznek.",
        rom: "Obiectele obtinute din aceasta cutie vor avea o calitate Bantuita.",
		swe: "Föremål som erhålls från denna låda kommer att ha den Hemsökta kvaliteten.",
	    fil: "Mga aytem na makukuha sa kahon ay magkakaroon ng kalidad na Haunted."
    },
    4: {
        eng: "Items obtained from this crate may have Strange and/or Unusual quality.",
        pol: "Przedmioty otrzymane z tej skrzynki mogą posiadać jakość Kuriozum i/lub Nietypowy Okaz.",
        sch: "从这个箱子中获得的物品可能具有奇异和/或稀有品质。",
        bra: "Itens obtidos por essa caixa podem ter a qualidade Estranha e/ou Incomum.",
        fre: "Les objets obtenus de cette caisse peuvent être de qualité Étrange et/ou Inhabituelle.",
        cze: "Předměty získané z této krabice můžou mít Strange a/nebo Unusual kvalitu.",
        hun: "Ebben a ládában található tárgyak Fura és/vagy Rendkívüli minőségűek lehetnek.",
        rom: "Obiectele obtinute din aceasta cutie pot avea calitate Stranie si/sau calitate Neobisnuita.",
		swe: "Föremål som erhålls från denna låda kan ha den Avvikande och/eller Ovanlig kvaliteten.",
		fil: "Mga aytem na makukuha sa kahon ay maaring Strange at/o Unusual na kalidad."
    },
    5: {
        eng: "Items obtained from this case may have Strange and/or Unusual quality.",
        pol: "Przedmioty otrzymane z tej skrzynki mogą posiadać jakość Kuriozum i/lub Nietypowy Okaz.",
        sch: "从这个箱子中获得的物品可能具有奇异和/或稀有品质。",
        bra: "Itens obtidos por essa caixa podem ter a qualidade Estranha e/ou Incomum.",
        fre: "Les objets obtenus de cette caisse peuvent être de qualité Étrange et/ou Inhabituelle.",
        cze: "Předměty získané z této krabice můžou mít Strange a/nebo Unusual kvalitu.",
        hun: "Ebben a táskában található tárgyak Fura és/vagy Rendkívüli minőségűek lehetnek.",
        rom: "Obiectele obtinuite din aceasta cutie pot avea calitate Stranie si/sau calitate Neobisnuita.",
		swe: "Föremål som erhålls från denna låda kan ha den Avvikande och/eller Ovanlig kvaliteten.",
		fil: "Mga aytem na makukuha sa kaso ay maaring Strange at/o Unusual na kalidad."
    },
    6: {
        eng: "Weapons obtained from this crate will have the Strange quality and cosmetics might have the Strange quality.",
        pol: "Bronie otrzymane z tej skrzynki będą posiadały jakość Kuriozum, a przedmioty ozdobne mogą posiadać jakość Kuriozum.",
        sch: "从这个箱子中获得的武器具有奇异品质，饰品可能具有奇异品质。",
        bra: "Armas obtidas por essa caixa terão a qualidade Estranha e cosméticos obtidos por essa caixa podem ter a qualidade Estranha.",
        fre: "Les armes obtenues de cette caisse seront de qualité Étrange et les cosmétiques peuvent être de qualité Étrange.",
        cze: "Zbraně získané z této krabice budou mít Strange kvalitu a kosmetické předměty můžou mít Strange kvalitu.",
        hun: "Ebben a ládában található fegyverek Fura minőségűek lesznek és a díszítők Fura minőségűek lehetnek.",
        rom: "Armele obtinute din aceasta cutie vor avea o calitate Stranie iar cosmeticele pot avea o calitate Stranie.",
		swe: "Vapen som erhålls från denna låda kommer att ha den Avvikande kvaliteten, och kosmetiker kan ha den Avvikande kvaliteten.",
		fil: "Ang mga armas na makukuha sa kahon ay magkakaroon ng kalidad na Strange at ang mga kosmetiko ay maaring magkaroon ng Strange na kalidad."
		
    },
    7: {
        eng: "Items obtained from this crate may have Unusual quality.",
        pol: "Przedmioty otrzymane z tej skrzynki mogą posiadać jakość Nietypowy Okaz.",
        sch: "从这个箱子中获得的物品可能具有稀有品质。",
        bra: "Itens obtidos por essa caixa podem ter qualidade Incomum.",
        fre: "Les objets obtenus de cette caisse peuvent être de qualité Inhabituelle.",
        cze: "Předměty získané z této krabice mohou mít Unusual kvalitu.",
        hun: "Ebben a ládában található tárgyak Rendkívüli minőségűek lehetnek.",
        rom: "Obiectele obtinute din aceasta cutie pot avea calitate Neobisnuita.",
		swe: "Föremål som erhålls från denna låda kan ha den Ovanlig kvaliteten.",
		fil: "Mga aytem na makukuha sa kahon ay maaring Unusual na kalidad."
    },
    8: {
        eng: "Items obtained from this crate may have Strange and Haunted quality.",
        pol: "Przedmioty otrzymane z tej skrzynki mogą posiadać jakość Kuriozum i Przekleństwo.",
        sch: "从这个箱子中获得的物品可能具有奇异和闹鬼品质。",
        bra: "Itens obtidos por essa caixa podem ter qualidade Estranha e Assombrada.",
        fre: "Les objets obtenus de cette caisse peuvent être de qualité Étrange et Hantée.",
        cze: "Předměty získané z této krabice mohou mít Strange a Haunted kvalitu.",
        hun: "Ebben a ládában található tárgyak Fura és Kísértetjárta minőségűek lehetnek.",
        rom: "Obiectele obtinute din aceasta cutie pot avea calitate Stranie si calitate Bantuita.",
		swe: "Föremål som erhålls från denna låda kan ha den Avvikande och Hemsökta kvaliteten.",
		fil: "Mga aytem na makukuha sa kahon ay maaring Strange o Haunted na kalidad."
    },
    9: {
        eng: "Options",
        pol: "Opcje",
        sch: "选项",
        bra: "Opções",
        fre: "Paramètres",
        cze: "Nastavení",
        hun: "Beállítások",
        rom: "Optiuni",
		swe: "Alternativ",
		fil: "Pagpipilian"
    },
    10: {
        eng: "Statistics",
        pol: "Statystyki",
        sch: "统计",
        bra: "Estatisticas",
        fre: "Statistiques",
        cze: "Statistiky",
        hun: "Statisztikák",
        rom: "Statistici",
		swe: "Statistik",
		fil: "Istatistika"
    },
    11: {
        eng: "Grid view",
        pol: "Widok siatki",
        sch: "网格视图",
        bra: "Visualização à grade",
        fre: "Vue en grille",
        cze: "Mřížkovaný pohled",
        hun: "Táblázat",
        rom: "Vizualizare grila",
		swe: "Rutnätvy",
		fil: "Tingnan ang parilya"
    },
    12: {
        eng: "Details view",
        pol: "Widok szczegółowy",
        sch: "详情视图",
        bra: "Visualização de detalhes",
        fre: "Vue détaillée",
        cze: "Detailní pohled",
        hun: "Részletes nézet",
        rom: "Vizualizare detalii",
		swe: "Detaljvy",
		fil: "Tingnan ang mga ditalye"
    },
    13: {
        eng: "Unbox my loot!",
        pol: "Otwórz skrzynkę!",
        sch: "开启战利品！",
        bra: "Desencaixotar a minha recompensa!",
        fre: "Déballer mon butin!",
        cze: "Otevřít!",
        hun: "Zsákmány kibontása!",
        rom: "Deschidemi bunurile!",
		swe: "Öppna min byte!",
		fil: "Buksan ang aking sako!"
    },
    14: {
        eng: "Random crate",
        pol: "Losowa skrzynka",
        sch: "随机箱子",
        bra: "Caixa aleatória",
        fre: "Caisse aléatoire",
        cze: "Náhodná bedna",
        hun: "Véletlenszerű láda",
        rom: "Cutie aleatorie",
		swe: "Slumpmässig låda",
		fil: "Sapalaran na kahon"
    },
    15: {
        eng: "Return",
        pol: "Wróć",
        sch: "返回",
        bra: "Retornar",
        fre: "Retour",
        cze: "Zpátky",
        hun: "Vissza",
        rom: "Intoarce",
		swe: "Återvänd",
		fil: "Balik"
    },
    16: {
        eng: "Effects",
        pol: "Efekty",
        sch: "效果",
        bra: "Efeitos",
        fre: "Effets",
        cze: "Efekty",
        hun: "Effektek",
        rom: "Efecte",
		swe: "Effekter",
		fil: "Mga epekto"
    },
    17: {
        eng: "Loot",
        pol: "Przedmioty",
        sch: "战利品",
        bra: "Recompensas",
        fre: "Butin",
        cze: "Předměty",
        hun: "Zsákmány",
        rom: "Bunuri",
		swe: "Byte",
		fil: "Sako"
    },
    18: {
        eng: "More info",
        pol: "Więcej informacji",
        sch: "更多信息",
        bra: "Mais informações",
        fre: "Plus d'informations",
        cze: "Více informací",
        hun: "További információk",
        rom: "Mai multe informatii",
		swe: "Mer information",
		fil: "Iba pang impormasyon"
    },
    19: {
        eng: "Unbox again",
        pol: "Otwórz kolejną",
        sch: "再次开箱",
        bra: "Desencaixotar novamente",
        fre: "Déballer à nouveau",
        cze: "Otevřít znova",
        hun: "Kinyitás újból",
        rom: "Deschide iar",
		swe: "Öppna igen",
		fil: "Ibuksan ulit"
    },
    20: {
        eng: "General",
        pol: "Ogólne",
        sch: "常规",
        bra: "Geral",
        fre: "Général",
        cze: "Přehled",
        hun: "Általános",
        rom: "General",
		swe: "Allmän",
		fil: "Pangkalahatan"
    },
    21: {
        eng: "Unusuals",
        pol: "Nietypowe Okazy",
        sch: "稀有",
        bra: "Incomuns",
        fre: "Inhabituels",
        cze: "Unusual předměty",
        hun: "Rendkívüliek",
        rom: "Obiecte neobisnuite",
		swe: "Ovanliga",
    },
    22: {
        eng: "Inventory",
        pol: "Ekwipunek",
        sch: "库存",
        bra: "Inventário",
        fre: "Inventaire",
        cze: "Inventář",
        hun: "Leltár",
        rom: "Inventar",
		swe: "Lager",
		fil: "Imbentaryo"
    },
    23: {
        eng: "Return to main menu",
        pol: "Wróć do menu głównego",
        sch: "返回主菜单",
        bra: "Retornar ao menu principal",
        fre: "Retour au menu principal",
        cze: "Zpět na hlavní menu",
        hun: "Vissza a főmenübe",
        rom: "Intoarce-te la meniu",
		swe: "Återvänd till huvudmenyn",
		fil: "Bumalik sa pangunahing menu"
    },
    24: {
        eng: "Return to list",
        pol: "Wróć do listy",
        sch: "返回列表",
        bra: "Retornar à lista",
        fre: "Retour à la liste",
        cze: "Zpátky do Inventáře",
        hun: "Vissza a listához",
        rom: "Intoarce-te la lista",
		swe: "Återvänd till lista",
		fil: "Bumalik sa listahan"
    },
    25: {
        eng: "Changelog",
        pol: "Lista zmian",
        sch: "更新日志",
        bra: "Histórico de mudanças",
        fre: "Changements récents",
        cze: "Přehled změn",
        hun: "Változási napló",
        rom: "Actualizari",
		swe: "Förändringslogg",
		fil: "Ang pagbabago"
    },
    26: {
        eng: "Credits",
        pol: "Twórcy",
        sch: "开发人员",
        bra: "Créditos",
        fre: "Crédits",
        cze: "Poděkování",
        hun: "Készítők",
        rom: "Credite",
		swe: "Medverkande",
		fil: "Mga Kredito"
    },
    27: {
        eng: "Off",
        pol: "Wył.",
        sch: "关",
        bra: "Desativado",
        fre: "Désactivé",
        cze: "Vyp",
        hun: "Ki",
        rom: "Oprit",
		swe: "Av",
		fil: "Ipatay"
    },
    28: {
        eng: "On",
        pol: "Wł.",
        sch: "开",
        bra: "Ativado",
        fre: "Activé",
        cze: "Zap",
        hun: "Be",
        rom: "Deschis",
		swe: "På",
		fil: "Ibukas"
    },
    29: {
        eng: "Reset",
        pol: "Resetuj",
        sch: "重置",
        bra: "Resetar",
        fre: "Réinitialiser",
        cze: "Reset",
        hun: "Visszaállítás",
        rom: "Resetare",
		swe: "Återställ",
		fil: "I-reset"
    },
    30: {
        eng: "This crate contains one of the following items:",
        pol: "Ta skrzynka zawiera jeden z następujących przedmiotów:",
        sch: "这个箱子包含下列物品之一：",
        bra: "Essa caixa contém um dos itens a seguir:",
        fre: "Cette caisse contient un des objets suivant:",
        cze: "Tato krabice obsahuje jeden z následujících předmětů:",
        hun: "Ez a láda az alábbiak egyikét tartalmazza:",
        rom: "Aceasta cutie contine unul dintre urmatoarele obiecte:",
		swe: "Denna låda innehåller något av följande:",
		fil: "Itong kahon ay naglalaman ng isa sa sumusunod na mga aytem:"
    },
    31: {
        eng: "Unusuals may have one of the following effects:",
        pol: "Nietypowe Okazy mogą posiadać jeden z następujących efektów:",
        sch: "稀有物品可能含有以下效果之一：",
        bra: "Itens incomuns poderão ter um dos efeitos a seguir:",
        fre: "Les objets Inhabituels peuvent être parmis les effets suivant:",
        cze: "Unusual předměty budou mohou mít jeden z následujících efektů:",
        hun: "A Rendkívüli effektek az alábbiak egyike lehet:",
        rom: "Obiectele Neobisnuite pot avea unul dintre urmatoarele efecte:",
		swe: "Ovanliga föremål kan ha en av följande effekter:",
		fil: "Unusuals ay maaring merong isa sa sumusunod na mga epekto:"
    },
    32: {
        eng: "Unusuals cannot be unboxed from this crate.",
        pol: "Ta skrzynka nie zawiera Nietypowych Okazów.",
        sch: "无法从这个箱子中获得稀有物品。",
        bra: "Itens incomuns não poderão ser obtidos por essa caixa.",
        fre: "Les objets Inhabituels ne peuvent pas être déballés de cette caisse.",
        cze: "Unusual předměty nemůžou být získány z této krabice.",
        hun: "Ebből a ládából nem nyithatók Rendkívüliek",
        rom: "Nu se pot deschide obiecte Neobisnuite din aceasta cutie.",
		swe: "Ovanliga föremål kan inte öppnas från denna låda.",
		fil: "Hindi maaring magbukas ng Unusuals sa kahon na ito."
    },
    33: {
        eng: "Unboxing your loot",
        pol: "Odpakowywanie twojego łupu",
        sch: "正在开启您的战利品",
        bra: "Desencaixotando a sua recompensa",
        fre: "Déballement du butin",
        cze: "Rozbalování obsahu",
        hun: "Zsákmányod kinyitása",
        rom: "Deschizanduti bunurile",
		swe: "Öppnar ditt byte",
		fil: "Binubukas ang iyong sako"
    },
    34: {
        eng: "New item acquired!",
        pol: "Zdobyto nowy przedmiot!",
        sch: "已获得新物品！",
        bra: "Novo item adquirido!",
        fre: "Nouvel objet acquis!",
        cze: "Získal jsi nový předmět!",
        hun: "Új tárgyat kaptál!",
        rom: "Obiect nou dobandit!",
		swe: "Nytt föremål införskaffad!",
		fil: "Bagong aytem ang nakuha mo!"
    },
    35: {
        eng: 'You <span id="unboxedtext">unboxed</span>:',
        pol: '<span id="unboxedtext">Odpakowujesz</span>:',
        sch: '您<span id="unboxedtext">开出了</span>：',
        bra: 'Você <span id="unboxedtext">desencaixotou</span>:',
        fre: 'Vous <span id="unboxedtext">avez ouvert</span>:',
        cze: '<span id="unboxedtext">Otevřel jsi</span>:',
        hun: '<span id="unboxedtext">Kibontottad</span>:',
        rom: 'Tu <span id="unboxedtext">deschis</span>:',
		swe: 'Du <span id="unboxedtext">öppnade</span>:',
		fil: 'Ikaw ay <span id="unboxedtext">bumukas</span>'
    },
    36: {
        eng: "★ Unusual Effect: ",
        pol: "★ Efekt: ",
        sch: "★ 粒子效果：",
        bra: "★ Efeito Incomum: ",
        fre: "★ Effet Inhabituel: ",
        cze: "★ Unusual Efekt:",
        hun: "★ Rendkívüli effekt:",
        rom: "★ Efect Neobisnuit: ",
		swe: "★ Ovanlig effekt: ",
		fik: "★ Unusual epekto:"
    },
    37: {
        eng: "Chance to unbox:",
        pol: "Szansa na zdobycie:",
        sch: "开出几率：",
        bra: "Chance de desencaixotar:",
        fre: "Chances de déballer:",
        cze: "Šance na otevření:",
        hun: "Esély a kibontásra:",
        rom: "Sansa de a deschide:",
		swe: "Möjlighet att öppna:",
		fil: "Tsansa sa pagbubukas:"
    },
    38: {
        eng: "Times unboxed:",
        pol: "Ilość posiadanych:",
        sch: "开出次数：",
        bra: "Vezes desencaixotadas:",
        fre: "Nombres de fois déballé:",
        cze: "Otevřeno tolikrát:",
        hun: "Kibontva ennyiszer:",
        rom: "Numarul deschiderilor:",
		swe: "Gånger öppnad:",
		fil: "Ilang times nabukas:"
    },
    39: {
        eng: "Unboxes since last Unusual:",
        pol: "Otwarcia pod rząd bez Nietypowego Okazu:",
        sch: "自上个稀有以来开箱数：",
        bra: "Desencaixotes desde o último item Incomum:",
        fre: "Caisses déballées depuis le dernier Inhabituel:",
        cze: "Otevření od posledního Unusual předmětu:",
        hun: "Kibontások az utolsó Rendkívüli óta:",
        rom: "Deschideri de la ultimul obiect Neobisnuit:",
		swe: "Antalet öppnade sedan senaste Ovanliga föremål:",
		fil: "Mga nabukas mula noong huling Unusual:"
    },
    40: {
        eng: "Unusuals unboxed:",
        pol: "Otrzymane Nietypowe Okazy:",
        sch: "已开出稀有：",
        bra: "Itens Incomuns desencaixotados:",
        fre: "Inhabituels déballés:",
        cze: "Získáno Unusual předmětů:",
        hun: "Rendkívüliek kibontva:",
        rom: "Obiecte Neobisnuite deschise:",
		swe: "Ovanliga föremål öppnade:",
		fil: "Nabukas na Unusual:"
    },
    41: {
        eng: "Total crates/cases opened:",
        pol: "Otwarte skrzynki:",
        sch: "已开箱：",
        bra: "Total de caixas abertas:",
        fre: "Total des caisses déballées:",
        cze: "Rozbalené krabice/bedny:",
        hun: "Kibontott ládák/táskák száma összesen:",
        rom: "Totalitatea cutiilor deschise:",
		swe: "Totalt öppnade lådor:",
		fil: "Kabuuan na kahon/kaso nabukas:"
    },
    42: {
        eng: "Money wasted on keys:",
        pol: "Pieniądze zmarnowane na klucze:",
        sch: "钥匙总花费：",
        bra: "Dinheiro gastado em chaves:",
        fre: "Argent gaspillée sur les clées:",
        cze: "Peníze utracené za klíče:",
        hun: "Kulcsokra elpazarolt pénz:",
        rom: "Bani folositi pe chei:",
		swe: "Pengar som slösas bort på nycklar:",
		fil: "Pera nasayang sa mga keys:"
    },
    43: {
        eng: "Shortest Unusual drought:",
        pol: "Najkrótsza przerwa w Nietypowych Okazach:",
        sch: "连续未开出稀有最短记录：",
        bra: "A menor seca de itens Incomuns:",
        fre: "Suite à sec d'Inhabituel la plus courte:",
        cze: "Nejkratší prodleva mezi Unusual předměty:",
        hun: "Két Rendkívüli között kinyitott legkevesebb láda/táska:",
        rom: "Cele mai putine cutii deschise fara a deschide un obiect Neobisnuit:",
		swe: "Kortaste Ovanliga föremål-torka:",
		fil: "Pinakamaikling kawalan ng Unusual:"
    },
    44: {
        eng: "Longest Unusual drought:",
        pol: "Najdłuższa przerwa w Nietypowych Okazach:",
        sch: "连续未开出稀有最长记录：",
        bra: "A maior seca de itens Incomuns:",
        fre: "Suite à sec d 'Inhabituel la plus longue:",
        cze: "Nejdelší prodleva mezi Unusual předměty:",
        hun: "Két Rendkívüli között kinyitott legtöbb láda/táska:",
        rom: "Cele mai multe cutii deschise fara a deschide un obiect Neobisnuit:",
		swe: "Längsta Ovanliga föremål-torka:",
		fil: "Pinakamahabang kawalan ng Unusual:"
    },
    45: {
        eng: '<span class="colorunique">Unique</span> items unboxed:',
        pol: 'Zdobyte <span class="colorunique">Unikalne</span> przedmioty:',
        sch: '已开出<span class="colorunique">独特</span>物品：',
        bra: 'Itens <span class="colorunique">Únicos</span> desencaixotados:',
        fre: 'Objet déballé<span class="colorunique">Unique</span>:',
        cze: '<span class="colorunique">Unique</span> předmětů získáno:',
        hun: '<span class="colorunique">Egyedi</span> tárgyak kibontva:',
        rom: '<span class="colorunique">unic</span> obiecte deschise:',
		swe: '<span class="colorunique">Unika</span> föremål öppnade:',
		fil: '<span class="colorunique">Unique</span> aytem nabukas:'
    },
    46: {
        eng: '<span class="colorstrange">Strange</span> items unboxed:',
        pol: 'Zdobyte przedmioty <span class="colorstrange">Kuriozalne</span>:',
        sch: '已开出<span class="colorstrange">奇异</span>物品：',
        bra: 'Itens <span class="colorstrange">Estranhos</span> desencaixotados:',
        fre: 'Objet déballé<span class="colorstrange">Étrange</span>:',
        cze: '<span class="colorstrange">Strange</span> předmětů získáno:',
        hun: '<span class="colorstrange">Fura</span> tárgyak kibontva:',
        rom: '<span class="colorstrange">Straniu</span> obiecte deschise:',
		swe: '<span class="colorstrange">Avvikande</span> föremål öppnade:',
		fil: '<span class="colorstrange">Strange</span> aytem nabukas:'
    },
    47: {
        eng: '<span class="colorhaunted">Haunted</span> items unboxed:',
        pol: 'Zdobyte <span class="colorhaunted">Przeklęte</span> przedmioty:',
        sch: '已开出<span class="colorhaunted">闹鬼</span>物品：',
        bra: 'Itens <span class="colorhaunted">Assombrados</span> desencaixotados:',
        fre: 'Objet déballé<span class="colorhaunted">Hanté</span>:',
        cze: '<span class="colorhaunted">Haunted</span> předmětů získáno:',
        hun: '<span class="colorhaunted">Kísértetjárta</span> tárgyak kibontva:',
        rom: '<span class="colorhaunted">Bantuit</span> obiecte deschise:',
		swe: '<span class="colorhaunted">Hemsökta</span> föremål öppnade:',
		fil: '<span class="colorhaunted">Haunted</span> aytem nabukas:'
    },
    48: {
        eng: '<span class="colordecorated">Decorated</span> items unboxed:',
        pol: 'Zdobyte <span class="colordecorated">Dekorowane</span> przedmioty:',
        sch: '已开出<span class="colordecorated">装饰</span>物品：',
        bra: 'Itens <span class="colordecorated">Decorados</span> desencaixotados:',
        fre: 'Objet déballé<span class="colordecorated">Décoré</span>:',
        cze: '<span class="colordecorated">Decorated</span> předmětů získáno:',
        hun: '<span class="colordecorated">Díszített fegyverek</span> kibontva:',
        rom: '<span class="colordecorated">Decorat</span> obiecte deschise:',
		swe: '<span class="colordecorated">Decorated</span> föremål öppnade:',
		fil: '<span class="colordecorated">Decorated</span> aytem nabukas:'
    },
    49: {
        eng: '<span class="colorunusual">Unusuals</span> unboxed:',
        pol: 'Zdobyte <span class="colorunusual">Nietypowe Okazy</span>:',
        sch: '已开出<span class="colorunusual">稀有</span>物品：',
        bra: 'Itens <span class="colorunusual">Incomuns</span> desencaixotados:',
        fre: 'Objet déballé<span class="colordecorated">Inhabituel</span>:',
        cze: '<span class="colorunusual">Unusual</span> předmětů získáno:',
        hun: '<span class="colorunusual">Rendkívüliek</span> kibontva:',
        rom: '<span class="colorunusual">Neobisnuite</span> deschis:',
		swe: '<span class="colorunusual">Ovanliga</span> föremål öppnade:',
		fil: '<span class="colorunusual">Unusuals</span> nabukas:'
    },
    50: {
        eng: "No unusuals... yet.",
        pol: "Brak Nietypowych Okazów.",
        sch: "还...没有稀有物品。",
        bra: "Nenhum item Incomum... por enquanto.",
        fre: "Pas d'inhabituels...pour l 'instant.",
        cze: "Zatím žádné Unusual předměty... zatím.",
        hun: "Nincsenek Rendkívüliek... még.",
        rom: "Niciun obiect Neobisnuit... inca.",
		swe: 'Inga Ovanliga föremål... än.',
		fil: "Wala pang unusuals... hanggat sa ngayon."
    },
    51: {
        eng: 'Unboxed <span id="statscratesdetailsunbox">0</span> times',
        pol: 'Otwarto <span id="statscratesdetailsunbox">0</span> razy',
        sch: '开箱 <span id="statscratesdetailsunbox">0</span> 次',
        bra: 'Desencaixotado <span id="statscratesdetailsunbox">0</span> vezes',
        fre: 'Débalé <span id="statscratesdetailsunbox">0</span> fois',
        cze: 'Otevřeno <span id="statscratesdetailsunbox">0</span> krát',
        hun: 'Kibontva ennyiszer: <span id="statscratesdetailsunbox">0</span>',
        rom: 'Deschis <span id="statscratesdetailsunbox">0</span> timpuri',
		swe: 'Öppnad <span id="statscratesdetailsunbox">0</span> gånger',
		fil: 'Ilang <span id="statscratesdetailsunbox">0</span> nabukas'
    },
    52: {
        eng: "No items... yet.<br>Go open some crates!",
        pol: "Brak przedmiotów.<br>Idź pootwierać skrzynki!",
        sch: "还...没有任何物品。<br>去开启一些箱子吧！",
        bra: "Sem itens... por enquanto.<br>Vai abrir algumas caixas!",
        fre: "Pas d'objets... pour l 'instant.<br>Allez déballer des caisses!",
        cze: "Zatím žádné předměty.. zatím.<br>Běž otevřít nějaké bedny!",
        hun: "Nincsenek tárgyak... még.<br>Nyiss ki pár ládát!",
        rom: "Niciun obict... inca.<br>Deschide niste cutii!",
		swe: "Inga föremål... än.<br>Gå och öppna några lådor!",
		fil: "Wala pang unusuals... hanggat ngayon.<br>Magbukas ka ng mga kahon!"
    },
    53: {
        eng: "Unboxed ",
        pol: "Otrzymano ",
        sch: "开出 ",
        bra: "Desencaixotou ",
        fre: "Déballé ",
        cze: "Otevřeno ",
        hun: "Kibontva",
        rom: "Deschis ",
		swe: "Öppnad ",
		fil: "Nabukas "
    },
    54: {
        eng: " times",
        pol: " razy",
        sch: " 次",
        bra: " vezes",
        fre: " fois",
        cze: " krát",
        hun: " alkalommal",
        rom: " timpuri",
		swe: " gånger",
		fil: " beses"
    },
    55: {
        eng: "Language:",
        pol: "Język:",
        sch: "语言：",
        bra: "Idioma:",
        fre: "Langue:",
        cze: "Jazyk:",
        hun: "Nyelv:",
        rom: "Limba:",
		swe: "Språk:",
		fil: "Lengguwahe:"
    },
    56: {
        eng: "Fast unboxing:",
        pol: "Szybkie otwieranie:",
        sch: "快速开箱：",
        bra: "Desencaixotamento rápido:",
        fre: "Déballage rapide:",
        cze: "Rychlé otevírání:",
        hun: "Gyors kibontás:",
        rom: "Deschidere rapida:",
		swe: "Snabböppning:",
		fil: "Pag-bilis sa bukas:"
    },
    57: {
        eng: 'Skips the unboxing countdown.<br>Also allows you to unbox rapidly by holding Space or the "Unbox again" button on the crate results screen.',
        pol: 'Pomija odliczanie podczas otwierania skrzynek.<br>Pozwala także na szybkie otwieranie skrzynek, przytrzymując spację lub przycisk "Otwórz kolejną" na ekranie wyników otwarcia.',
        sch: "跳过开箱倒计时。 <br>这也使您可以按住空格键或者点击开箱结果的“再次开箱”按钮来快速开箱。",
        bra: 'Desativa a contagem regressiva.<br>Também permite que você desencaixote rapidamente segurando a tecla Espaço ou o botão "Desencaixotar novamente" nos resultados do seu desencaixotamento.',
        fre: 'Saute le compte-à-rebours du déballage.',
        cze: 'Přeskakuje rozbalovací odpočet.<br>Taktéž umožňuje rychlé otevírání držením mezerníku nebo "Otevřít znova" tlačítka na obrazovce výsledků.',
        hun: 'Kihagyja a visszaszámlálást kibontáskor.<br>Így tudsz gyorsabban kibontani a Space gomb lenyomva tartásával vagy a "Kinyitás újból" gomb lenyomásával a kibontott zsákmány képernyőn.',
        rom: 'Treci peste timpul pentru deschiderea cutiei.<br>Deasemenea iti permite sa deschizi rapid tinand apasat Space sau butonul "Deschide iar" afisat pe ecranul rezultatului cutiei.',
		swe: 'Hoppar över nedräkningen för öppningen.<br>Låter dig också öppna snabbt genom att hålla ner mellanslag eller knappen "Öppna igen" på lådans resultatskärm.',
		fil: 'I-skip ang pag-bilang ng pagbubukas.<br>Ay rin pinapayagan ka magbukas ng mabilis ipag hinawakan mo ang Space o ang "Ibuksan ulit" button sa mga resulta ng mga kahon na screen.'
    },
    58: {
        eng: "Amoled theme:",
        pol: "Tryb Amoled:",
        sch: "Amoled 主题：",
        bra: "Tema escuro:",
        fre: "Thème Amoled:",
        cze: "Amoled pozadí:",
        hun: "Amoled téma:",
        rom: "Tema amoled:",
		Swe: "Amoled-tema:",
		fil: "Amoled-tema:"
    },
    59: {
        eng: "Change background color to black.",
        pol: "Zmienia kolor tła na czarny.",
        sch: "修改背景颜色为黑色。",
        bra: "Altera a cor do fundo para preto.",
        fre: "Change la couleur de fond au noir.",
        cze: "Změní barvu pozadí na černou.",
        hun: "Fekete háttér beállítása.",
        rom: "Schimba culoarea fundalului ca negru.",
		swe: "Ändra bakgrundsfärg till svart.",
		fil: "Baguhin ang kulay ng background na itim."
    },
    60: {
        eng: "Reset save:",
        pol: "Zresetuj zapis:",
        sch: "重置存档：",
        bra: "Resetar a sua data:",
        fre: "Réinitialiser la sauvegarde:",
        cze: "Resetovat historii:",
        hun: "Mentések törlése:",
        rom: "Reseteaza salvarea:",
		swe: "Återställ sparning:",
		fil: "I-reset ang save:"
    },
    61: {
        eng: "Reset stats and all unboxed items, including unusuals.<br>Will ask for confirmation only once.",
        pol: "Resetuje statystyki i wszystkie zdobyte przedmioty, łącznie z Nietypowymi Okazami.",
        sch: "重置所有统计数据与物品，包括稀有物品。<br>我们只会询问一次。",
        bra: "Reseta as suas estatísticas e todos itens desencaixotados, incluindo itens Incomuns.<br>Só vai pedir confirmação uma vez.",
        fre: "Réinitialise les statistiques ainsi que les objets déballés, incluants les Inhabituels.<br>Ne demande la confirmation qu'une fois.",
        cze: "Resetuje statistiky a historii všech rozbalených předmětů, včetně Unusual.<br>Na potvrzení se zeptám pouze jednou.",
        hun: "Lenullázza a statisztikákat és az összes kibontott tárgyat eltünteti, a Rendkívülieket is.<br>Megnyomás után még egyszer jóvá kell hagyni.",
        rom: "Reseteaza statisticile si toate obiectele deschise, incluzand obiectele Neobisnuite.<br>Se va intreba decat o singura data pentru confirmare.",
		swe: "Återställ statistik och alla objekt, inklusive Ovanliga.<br>Ber bara om bekräftelse en gång.",
		fil: "I-reset ang mga istatistika at ang lahat nabukas mong aytems,kabilang unusuals.<br>Itatanong ka para sa kumpirmasyon isa lang."
    },
    62: {
        eng: "Custom Crate Depression Mode:",
        pol: "Własny tryb Skrzynkowej Depresji:",
        sch: "自定义战利品大爆发模式：",
        bra: "Modo Personalizado da Grande Depressão de Caixas:",
        fre: "Mode Custom Caisse Dépression:",
        cze: "Vlastní Crate Depression mód:",
        hun: "Egyedi ládanyitási mód:",
        rom: "Cutie Personalizata Modul Depresie:",
		swe: "Anpassat Låddepressionsläge:",
		fil: "Custom Kahon Depresyon Mode:" 

    },
    63: {
        eng: "Crash the economy in your own style! This option lets you always unbox Unusuals, Stranges, professional killstreak kits, bonus items or items of specific grade.<br>Enabling any of those options will disable stats and saving!",
        pol: "Rozwal ekonomię w swoim własnym stylu! Ta opcja pozwoli ci zawsze otrzymać Nietypowe Okazy, przedmioty Kuriozalne, zestawy z profesjonalną serią zabójstw, przedmioty dodatkowe lub przedmioty o określonym stopniu.<br>Włączenie jakiejkolwiek z tych opcji wyłączy statystyki i zapisywanie!",
        sch: "用您自己的方式摧毁游戏内经济！此选项可以让您总是开出稀有、奇异、职业连杀记录式或者指定等级的物品，以及额外奖励物品。<br>启用此处任何选项都会禁用统计与存档！",
        bra: "Destrua a economia no seu estilo! Essa opção te deixa sempre desencaixotar itens Incomuns, Estranhos, Kits de Combo Profissionais, itens de bônus ou itens de nível especifico.<br>Ativando qualquer uma dessas opções vai desativar estatísticas e a habilidade de salvar a sua data!",
        fre: "Ruinez l'économie à votre façon! Cette option vous laisse ouvrir que des Inhabituels, Étranges, kits de killstreak professionels, objets bonis ou objets d'un grade spécifique.<br>Activer une ce des options désactive les statistiques et la sauvegarde!",
        cze: "Znič ekonomiku ve svém vlastním stylu! Tato možnost ti umožňí pokaždé získat Unusual nebo Strange předmět, případně prof. killstreak kit, bonusové předměty, nebo předmět určité kvality.<br>Povolením této funkce se pozastaví ukládání statistiky a historie otevření!",
        hun: "Tedd tönkre a gazdaságot a saját stílusodban! Ezekkel a beállításokkal elérheted, hogy mindig Rendkívüliket, Furákat, Hivatásos ámokfutás készleteket, bónusz tárgyakat vagy tárgyakat bizonyos fokozatba nyiss ki. Bármelyik bekapcsolása esetén nem számítanak bele a statisztikákba és nem lesz elmentve sem!",
        rom: "Distruge economia in propriul stil! Aceasta optiune te va lasa intotdeauna sa deschizi obiecte Neobisnuite, obiecte Stranii, Truse Profesionale de Omoruri Consecutive, obiecte bonus sau obiecte de un grad specific.<br>Pornirea oricarui optiune dintre acestea va dezactiva statisticile si salvarile!",
		swe: 'Krascha ekonomin i din egen stil! Med det här alternativet kan du alltid öppna Ovanliga, Avvikande, professionella killstreak-kit, bonusartiklar eller föremål av specifik kvalitet.<br>Om du aktiverar något av dessa alternativ inaktiveras statistik och sparning!',
		fil: "I-crash mo ang ekonomiya sa iyong sariling style! Itong opsyon ay ipapaalam ka napalagi magbukas ng mga Unusuals, Stranges, professional killstreak kits, bonus aytem o mga ibang aytem na sa tiyak na grado.<br>Pag-gamit ng isa sa mga opsyons ay ipapatay ang istatistika at saving!"
    },
    64: {
        eng: 'Made by <a href="https://steamcommunity.com/id/kubikill" target="_blank" rel="noopener">kubikill</a>',
        pol: 'Stworzone przez <a href="https://steamcommunity.com/id/kubikill" target="_blank" rel="noopener">kubikilla</a>',
        sch: '由 <a href="https://steamcommunity.com/id/kubikill" target="_blank" rel="noopener">kubikill</a> 制作',
        bra: 'Feito por <a href="https://steamcommunity.com/id/kubikill" target="_blank" rel="noopener">kubikill</a>',
        fre: 'Créé par <a href="https://steamcommunity.com/id/kubikill" target="_blank" rel="noopener">kubikill</a>',
        cze: 'Vytvořeno <a href="https://steamcommunity.com/id/kubikill" target="_blank" rel="noopener">kubikillem</a>',
        hun: 'Készítette: <a href="https://steamcommunity.com/id/kubikill" target="_blank" rel="noopener">kubikill</a>',
        rom: 'Creata de <a href="https://steamcommunity.com/id/kubikill" target="_blank" rel="noopener">kubikill</a>',
		swe: 'Gjord av <a href="https://steamcommunity.com/id/kubikill" target="_blank" rel="noopener">kubikill</a>',
		fil: 'Gawa ni <a href="https://steamcommunity.com/id/kubikill" target="_blank" rel="noopener">kubikill</a>'
    },
    65: {
        eng: 'Special thanks to <a href="https://steamcommunity.com/profiles/76561198117761965" target="_blank" rel="noopener">Gorno</a>',
        pol: 'Specjalne podziękowania dla <a href="https://steamcommunity.com/profiles/76561198117761965" target="_blank" rel="noopener">Gorno</a>',
        sch: '特别感谢 <a href="https://steamcommunity.com/profiles/76561198117761965" target="_blank" rel="noopener">Gorno</a>',
        bra: 'Agradecimentos especiais para <a href="https://steamcommunity.com/profiles/76561198117761965" target="_blank" rel="noopener">Gorno</a>',
        fre: 'Remerciments spéciaux à <a href="https://steamcommunity.com/profiles/76561198117761965">Gorno</a>',
        cze: 'Speciální poděkování <a href="https://steamcommunity.com/profiles/76561198117761965" target="_blank" rel="noopener">Gornovi</a>',
        hun: 'Külön köszönet <a href="https://steamcommunity.com/profiles/76561198117761965" target="_blank" rel="noopener">Gornonak</a>',
        rom: 'Multumiri multe lui <a href="https://steamcommunity.com/profiles/76561198117761965" target="_blank" rel="noopener">Gorno</a>',
		swe: 'Särskilt tack till <a href="https://steamcommunity.com/profiles/76561198117761965" target="_blank" rel="noopener">Gorno</a>',
		fil: 'Maraming salamat kay <a href="https://steamcommunity.com/profiles/76561198117761965" target="_blank" rel="noopener">Gorno</a>'
    },
    66: {
        eng: 'Unusual effect images made by <a href="https://backpack.tf" target="_blank" rel="noopener">backpack.tf</a>',
        pol: 'Obrazki efektów Nietypowych Okazów stworzone przez <a href="https://backpack.tf" target="_blank" rel="noopener">backpack.tf</a>',
        sch: '稀有效果图像由 <a href="https://backpack.tf" target="_blank" rel="noopener">backpack.tf</a> 制作',
        bra: 'Imagens de efeitos incomuns feito por <a href="https://backpack.tf" target="_blank" rel="noopener">backpack.tf</a>',
        fre: "Images d'effets Inhabituels faites par <a href='https://backpack.tf'>backpack.tf</a>",
        cze: 'Obrázky Unusual efektů ze stránky <a href="https://backpack.tf" target="_blank" rel="noopener">backpack.tf</a>',
        hun: 'Rendkívüli effekt képeket készítette: <a href="https://backpack.tf" target="_blank" rel="noopener">backpack.tf</a>',
        rom: 'Pozele cu efectele Neobisnuite facute de <a href="https://backpack.tf" target="_blank" rel="noopener">backpack.tf</a>',
		swe: 'Ovanliga effektbilder gjorda av <a href="https://backpack.tf" target="_blank" rel="noopener">backpack.tf</a>',
		fil: 'Ang mga imahe ng epekto ng Unusual ay gawa ng <a href="https://backpack.tf" target="_blank" rel="noopener">backpack.tf</a>'
    },
    67: {
        eng: "Bulk unboxing",
        pol: "Otwórz wiele skrzynek",
        sch: "批量开箱",
        bra: "Desencaixotamento em massa",
        fre: "Déballage de masse",
        cze: "Hromadné otevírání",
        hun: "Kinyitás nagy tételben",
        rom: "Deschidere in masa",
		swe: "Massöppning",
		fil: "Maramihang pagbukas"
    },
    68: {
        eng: "How many crates/cases do you want to open?",
        pol: "Ile skrzynek chcesz otworzyć?",
        sch: "您想要开启几个箱子？",
        bra: "Quantas caixas você deseja abrir?",
        fre: "Combien de caisses voulez-vous déballer?",
        cze: "Kolik krabic/beden si přeješ otevřít?",
        hun: "Mennyi ládát/táskát szeretnél kinyitni?",
        rom: "Cate cutii vrei sa deschizi?",
		swe: "Hur många lådor vill du öppna?",
		fil: "Ilang mga kahon/kaso ang gusto mong ibuksan?"
    },
    69: {
        eng: "Translators:",
        pol: "Tłumacze:",
        sch: "译者：",
        bra: "Tradutores:",
        fre: "Traducteurs:",
        cze: "Překladatelé:",
        hun: "Fordítók:",
        rom: "Traducatori:",
		swe: "Översättare:",
		fil: "Tagasalin:"
		
    },
    70: {
        eng: 'Simplified Chinese',
        pol: 'Chiński (uproszczony)',
        sch: '‪中文（简体）',
        bra: 'Chinês Simplificado',
        fre: 'Chinois simplifié',
        cze: 'Zjednodušená Čínština',
        hun: 'Egyszerűsített kínai',
        rom: 'Chineaza simplificata',
		swe: 'Förenklad Kinesiska',
		fil: 'Simpleng Tsino'
    },
    71: {
        eng: "Strange",
        pol: "Kuriozum",
        sch: "奇异",
        bra: "Estranho",
        fre: "Étrange",
        cze: "Strange",
        hun: "Fura",
        rom: "Straniu",
		swe: "Avvikande"
    },
    72: {
        eng: "Haunted",
        pol: "Przekleństwo",
        sch: "闹鬼",
        bra: "Assombrado",
        fre: "Hanté",
        cze: "Haunted",
        hun: "Kísértetjárta",
        rom: "Bantuit",
		swe: "Hemsökt"
    },
    73: {
        eng: "Unusual",
        pol: "Nietypowy Okaz",
        sch: "稀有",
        bra: "Incomum",
        fre: "Inhabituel",
        cze: "Unusual",
        hun: "Rendkívüli",
        rom: "Neobisnuit",
		swe: "Ovanlig"
    },
    74: {
        eng: "or a random Unusual hat!",
        pol: "lub losowy Nietypowy Okaz!",
        sch: "或者是一件随机的稀有饰品！",
        bra: "ou um item Incomum aleatório!",
        fre: "ou encore un chapeau Inhabituel aléatoire!",
        cze: "nebo speciální extrémně vzácný předmět!",
        hun: "vagy egy véletlenszerű Rendkívüli sapka!",
        rom: "sau o palarie Neobisnuita aleatorie!",
		swe: "eller en slumpmässig ovanlig hatt!",
		fil: "o isang sapalarang sumbrerong Unusual"
    },
    75: {
        eng: "Series #(NUM)",
        pol: "Seria #(NUM)",
        sch: "编号 #(NUM)",
        bra: "Série #(NUM)",
        fre: "Séries #(NUM)",
        cze: "Série #(NUM)",
        hun: "(NUM). sorozatú",
        rom: "Seria #(NUM)",
		swe: "Serie #(NUM)",
		fil: "Serye #(NUM)"
    },
    76: {
        eng: "Seriesless",
        pol: "Bez serii",
        sch: "无编号",
        bra: "Sem séries",
        fre: "Sans série",
        cze: "Bez série",
        hun: "Sorozat nélküli",
        rom: "Fara serie",
		swe: "Serielös",
		fil: "Walang serye"
    },
    77: {
        eng: "Save reset!",
        pol: "Zresetowano!",
        sch: "存档已重置！",
        bra: "Resetar a sua data",
        fre: "Sauvegarde réinitialisée!",
        cze: "Uložit reset!",
        hun: "Mentések törölve!",
        rom: "Salveaza resetarea!",
		swe: "Spara återställning!",
		fil: "I-save ang reset!"
    },
    78: {
        eng: "Are you sure?",
        pol: "Na pewno?",
        sch: "您确定吗？",
        bra: "Você tem certeza?",
        fre: "Êtes-vous certain?",
        cze: "Opravdu?",
        hun: "Biztos vagy benne?",
        rom: "Esti sigur?",
		swe: "Är du säker?",
		fil: "Sigurado ka ba?"
    },
    79: {
        eng: "Effect:",
        pol: "Efekt:",
        sch: "效果：",
        bra: "Efeito:",
        fre: "Effet:",
        cze: "Efekt:",
        hun: "Effekt:",
        rom: "Efect:",
		swe: "Effekt",
		fil: "Epekto"
    },
    80: {
        eng: "Factory New",
        pol: "Prosto z fabryki",
        sch: "崭新出厂",
        bra: "Nova de Fábrica",
        fre: "Neuve",
        cze: "Zbrusu nový",
        hun: "Gyári új",
        rom: "Nou din fabrica",
		swe: "Fabriksny"
    },
    81: {
        eng: "Minimal Wear",
        pol: "Lekkie zużycie",
        sch: "略有磨损",
        bra: "Pouco Usada",
        fre: "Très peu usée",
        cze: "Lehce opotřebený",
        hun: "Kevéssé használt",
        rom: "Uzura minima",
		swe: "Minimalt slitage"
    },
    82: {
        eng: "Field-Tested",
        pol: "Po testach bojowych",
        sch: "久经沙场",
        bra: "Testada em Campo",
        fre: "Testée sur le terrain",
        cze: "Opotřebený",
        hun: "Harctéren tesztelt",
        rom: "Testat pe teren",
		swe: "Fält-Testad"
    },
    83: {
        eng: "Well-Worn",
        pol: "Mocne zużycie",
        sch: "破损不堪",
        bra: "Bem Desgastada",
        fre: "Usée",
        cze: "Silně opotřebený",
        hun: "Viseltes",
        rom: "Bine folosit",
		swe: "Väl sliten"
    },
    84: {
        eng: "Battle Scarred",
        pol: "Po ciężkich walkach",
        sch: "战痕累累",
        bra: "Veterana de Guerra",
        fre: "Marquées par les combats",
        cze: "Poničený bojem",
        hun: "Háború tépázta",
        rom: "Zgariat in lupta",
		swe: "Kamp-ärrad"
    },
    85: {
        eng: "Steam Group",
        pol: "Grupa na Steamie",
        sch: "Steam 组",
        bra: "Grupo da Steam",
        fre: "Groupe Steam",
        cze: "Steam skupina",
        hun: "Steam csoport",
        rom: "Grup de steam",
		swe: "Steam Grupp",
		fil: "Grupo sa Steam"
    },
    86: {
        eng: "Mute sound",
        pol: "Wycisz dźwięk",
        sch: "静音",
        bra: "Silenciar sons",
        fre: "Mettre en sourdine",
        cze: "Ztlumit zvuky",
        hun: "Némítás",
        rom: "Opreste sunetul",
		swe: "Stäng av ljud",
		fil: "Ipatay ang tunog"
    },
    87: {
        eng: "Settings",
        pol: "Ustawienia",
        sch: "设置",
        bra: "Definições",
        fre: "Paramètres",
        cze: "Nastavení",
        hun: "Beállítások",
        rom: "Setari",
		swe: "Inställningar"
    },
    88: {
        eng: "About/Donate",
        pol: "Info/Dotacje",
        sch: "关于/捐赠",
        bra: "Sobre/Doar",
        fre: "Infos/Faire un don",
        cze: "Info/Příspěvky",
        hun: "Rólunk/adomány",
        rom: "Despre/Doneaza",
		swe: "Om/donera",
		fil: "Impormasyon/Donate"
    },
    89: {
        eng: "Donations",
        pol: "Dotacje",
        sch: "捐赠",
        bra: "Doações",
        fre: "Dons",
        cze: "Příspěvky",
        hun: "Adományok",
        rom: "Donatii",
		swe: "Donationer"
    },
    90: {
        eng: '<span class="colorstrange">Strange</span> <span class="colorunusual">Unusuals</span> unboxed:',
        pol: 'Zdobyte <span class="colorstrange">Kuriozalne</span> <span class="colorunusual">Nietypowe Okazy</span>:',
        sch: '已开出<span class="colorstrange">奇异</span><span class="colorunusual">稀有</span>物品：',
        bra: 'Itens <span class="colorstrange">Estranhos</span> <span class="colorunusual">Incomuns</span> desencaixotados:',
        fre: 'Objets<span class="colorstrange">Étranges</span> <span class="colorunusual">Inhabituels</span> déballés:',
        cze: '<span class="colorstrange">Strange</span> <span class="colorunusual">Unusual</span> předmětů získáno:',
        hun: '<span class="colorstrange">Fura</span> <span class="colorunusual">Rendkívüliek</span> kibontva:',
        rom: '<span class="colorstrange">Straniu</span> <span class="colorunusual">Neobisnuite</span> unboxed:',
		swe: '<span class="colorstrange">Avvikande</span> <span class="colorunusual">Ovanliga</span> öppnade:',
		fil: '<span class="colorstrange">Strange</span> <span class="colorunusual">Unusuals</span> nabukas:'
    },
    91: {
        eng: "Graded items unboxed:",
        pol: "Zdobyte przedmioty z stopniem:",
        sch: "已开出等级物品：",
        bra: "Itens com nível desencaixotados:",
        fre: "Objets gradés déballés:",
        cze: "Získáno předmětů dané třídy:",
        hun: "Tárgyak fokozattal kibontva:",
        rom: "Obiecte gradate deschise:",
		swe: "Betygsatta objekt öppnade:",
		fil: "Gradong aytem nabukas:"
    },
    92: {
        eng: '<span class="colorcivilian">Civilian</span> items unboxed:',
        pol: 'Zdobyte przedmioty st. <span class="colorcivilian">Cywil</span>:',
        sch: '已开出<span class="colorcivilian">民用级</span>物品：',
        bra: 'Itens de nível <span class="colorcivilian">Civil</span> desencaixotados:',
        fre: 'Objets de grade <span class="colorcivilian">Civil</span>déballés:',
        cze: '<span class="colorcivilian">Civilian</span> předmětů získáno:',
        hun: '<span class="colorcivilian">Civil</span> tárgyak kibontva:',
        rom: '<span class="colorcivilian">Civil</span> obiecte deschise:',
		swe: '<span class="colorcivilian">Civil</span> föremål öppnad:',
		fil: '<span class="colorcivilian">Sibilyan</span> aytem nabukas:'
    },
    93: {
        eng: '<span class="colorfreelance">Freelance</span> items unboxed:',
        pol: 'Zdobyte przedmioty st. <span class="colorfreelance">Wolny Strzelec</span>:',
        sch: '已开出<span class="colorfreelance">不羁级</span>物品：',
        bra: 'Itens de nível <span class="colorfreelance">Autônomo</span> desencaixotados:',
        fre: 'Objets de grade <span class="colorfreelance">Freelance</span>déballés:',
        cze: '<span class="colorfreelance">Freelance</span> předmětů získáno:',
        hun: '<span class="colorfreelance">Szabadúszó</span> tárgyak kibontva:',
        rom: '<span class="colorfreelance">Independent</span> obiecte deschise:',
		swe: '<span class="colorfreelance">Frilans</span> föremål öppnade:',
		fil: '<span class="colorfreelance">Freelance</span> aytem nabukas:'
    },
    94: {
        eng: '<span class="colormercenary">Mercenary</span> items unboxed:',
        pol: 'Zdobyte przedmioty st. <span class="colormercenary">Najemnik</span>:',
        sch: '已开出<span class="colormercenary">雇佣级</span>物品：',
        bra: 'Itens de nível <span class="colormercenary">Mercenário</span> desencaixotados:',
        fre: 'Objets de grade <span class="colormercenary">Mercenaire</span>déballés:',
        cze: '<span class="colorfreelance">Mercenary</span> předmětů získáno:',
        hun: '<span class="colormercenary">Zsoldos</span> tárgyak kibontva:',
        rom: '<span class="colormercenary">Mercenar</span> obiecte deschise:',
		swe: '<span class="colormercenary">Legosoldat</span> föremål öppnade:',
		fil: '<span class="colormercenary">Mersenary</span> aytem nabukas:'
    },
    95: {
        eng: '<span class="colorcommando">Commando</span> items unboxed:',
        pol: 'Zdobyte przedmioty st. <span class="colorcommando">Komandos</span>:',
        sch: '已开出<span class="colorcommando">特种级</span>物品：',
        bra: 'Itens de nível <span class="colorcommando">Comando</span> desencaixotados:',
        fre: 'Objets de grade <span class="colorcommando">Commando</span>déballés:',
        cze: '<span class="colorcommando">Commando</span> předmětů získáno:',
        hun: '<span class="colorcommando">Kommandós</span> tárgyak kibontva:',
        rom: '<span class="colorcommando">Comando</span> obiecte deschise:',
		swe: '<span class="colorcommando">Kommando</span> föremål öppnade:',
		fil: '<span class="colorcommando">Kommando</span> aytem nabukas:'
    },
    96: {
        eng: '<span class="colorassassin">Assassin</span> items unboxed:',
        pol: 'Zdobyte przedmioty st. <span class="colorassassin">Asasyn</span>:',
        sch: '已开出<span class="colorassassin">刺杀级</span>物品：',
        bra: 'Itens de nível <span class="colorassassin">Assassino</span> desencaixotados:',
        fre: 'Objets de grade <span class="colorassassin">Assassin</span>déballés:',
        cze: '<span class="colorassassin">Assassin</span> předmětů získáno:',
        hun: '<span class="colorassassin">Orgyilkos</span> tárgyak kibontva:',
        rom: '<span class="colorassassin">Asasin</span> obiecte deschide:',
		swe: '<span class="colorassassin">Lönnmördar</span> föremål öppnade:',
		fil: '<span class="colorassassin">Assassino</span> aytem nabukas:'
    },
    97: {
        eng: '<span class="colorelite">Elite</span> items unboxed:',
        pol: 'Zdobyte przedmioty st. <span class="colorelite">Elita</span>:',
        sch: '已开出<span class="colorelite">精英级</span>物品：',
        bra: 'Itens de nível <span class="colorelite">Elite</span> desencaixotados:',
        fre: 'Objets de grade <span class="colorelite">Élite</span>déballés:',
        cze: '<span class="colorelite">Elite</span> předmětů získáno:',
        hun: '<span class="colorelite">Elit</span> tárgyak kibontva:',
        rom: '<span class="colorelite>Elită</span> obiecte deschise:',
		swe: '<span class="colorelite">Elit</span> föremål öppnade:',
		fil: '<span class="colorelite">Elito</span> aytem nabukas:'
    },
    98: {
        eng: "Items with wear unboxed:",
        pol: "Zdobyte przedmioty z stopniem jakości:",
        sch: "已开出磨损物品：",
        bra: "Itens com desgaste desencaixotados:",
        fre: "Apparence extérieur des objets déballés:",
        cze: "Předmětů se skinem získáno:",
        hun: "Viseletekkel rendelkező tárgyak kibontva:",
        rom: "Obiecte folosite deschise:",
		swe: "Föremål med slitage öppnade:",
		fil: "Aytem kasamang grado nabukas:"
    },
    99: {
        eng: "Factory New items unboxed:",
        pol: "Zdobyte fabrycznie nowe przedmioty:",
        sch: "已开出崭新出厂物品：",
        bra: "Itens Novos de Fábrica desencaixotados:",
        fre: "Objets d'extérieur Neuve déballés:",
        cze: "Zbrusu nových předmětů získáno:",
        hun: "Gyári új tárgyak kibontva:",
        rom: "Obiecte noi din fabrica deschise:",
		swe: "Fabriksnya föremål öppnade:"
    },
    100: {
        eng: "Minimal Wear items unboxed:",
        pol: "Zdobyte lekko używane przedmioty:",
        sch: "已开出略有磨损物品：",
        bra: "Itens Pouco Usados desencaixotados:",
        fre: "Objets d'extérieur Peu Usée déballés",
        cze: "Lehce opotřebených předmětů získáno:",
        hun: "Kevéssé használt tárgyak kibontva:",
        rom: "Obiecte cu uzura minima deschise:",
		swe: "Minimalt slitage föremål öppnade:"
    },
    101: {
        eng: "Field-Tested items unboxed:",
        pol: "Zdobyte przetestowane w boju przedmioty:",
        sch: "已开出久经沙场物品：",
        bra: "Itens Testados em Campo desencaixotados:",
        fre: "Objets d'extérieur Testée sur le Terrain déballés:",
        cze: "Opotřebených předmětů získáno:",
        hun: "Harctéren tesztelt tárgyak kibontva:",
        rom: "Obiecte testate pe teren deschise:",
		swe: "Fält-testade föremål öppnade:"
    },
    102: {
        eng: "Well-Worn items unboxed:",
        pol: "Zdobyte przedmioty z śladami używania:",
        sch: "已开出破损不堪物品：",
        bra: "Itens Bem Desgastados desencaixotados:",
        fre: "Objets d'extérieur Usée déballés:",
        cze: "Silně opotřebených předměů získáno:",
        hun: "Viseltes tárgyak kibontva:",
        rom: "Obiecte bine folosite deschise:",
		swe: "Väl slitna föremål öppnade:"
    },
    103: {
        eng: "Battle Scarred items unboxed:",
        pol: "Zdobyte zaprawione w walkach przedmioty:",
        sch: "已开出战痕累累物品：",
        bra: "Itens Veteranas de Guerra desencaixotados:",
        fre: "Objets d'extérieurs Marquée par les Combats déballés:",
        cze: "Předmětů poničených bojem získáno:",
        hun: "Háború tépázta tárgyak kibontva:",
        rom: "Obiecte zgariate in lupta deschise:",
		swe: "Kamp-ärrade föremål öppnade:"
    },
    104: {
        eng: "General",
        pol: "Ogólne",
        sch: "常规",
        bra: "Geral",
        fre: "Général",
        cze: "Přehled",
        hun: "Általános",
        rom: "General",
		swe: "Allmän",
		fil: "Pangkalahatan"
    },
    105: {
        eng: "Quality",
        pol: "Jakość",
        sch: "品质",
        bra: "Qualidade",
        fre: "Qualité",
        cze: "Kvalita",
        hun: "Minőség",
        rom: "Calitate",
		swe: "Kvalitet",
		fil: "Kalidad"
    },
    106: {
        eng: "Grades",
        pol: "Stopnie",
        sch: "等级",
        bra: "Níveis",
        fre: "Grades",
        cze: "Třídy",
        hun: "Fokozatok",
        rom: "Graduri",
		swe: "Betyg",
		fil: "Mga grado"
    },
    107: {
        eng: "Wears",
        pol: "Stopnie zużycia",
        sch: "磨损",
        bra: "Desgastes",
        fre: "Extérieur",
        cze: "Skiny",
        hun: "Külső",
        rom: "Folosire",
		swe: "Slitage"
    },
    108: {
        eng: "Average unboxes per Unusual:",
        pol: "Średnia ilość otwarć na Nietypowy Okaz:",
        sch: "获得稀有平均开箱数：",
        bra: "Média de desencaixotamento por item Incomum:",
        fre: "Déballage moyen par Inhabituel:",
        cze: "Průměr počtu otevření za Unusual předmět:",
        hun: "Átlag kibontások száma két Rendkívüli között:",
        rom: "Deschidere in medie per obiecte Neobisnuite:",
		swe: "Genomsnittliga öppningar per Ovanligt föremål:",
		fil: "Karaniwang buksan sa bawat na Unusual:"
    },
    109: {
        eng: '<span class="colorstrange">Strange</span> <span class="colorhaunted">Haunted</span> items unboxed:',
        pol: 'Zdobyte <span class="colorstrange">Kuriozalne</span> <span class="colorhaunted">Nawiedzone</span> przedmioty:',
        sch: '已开出<span class="colorstrange">奇异</span><span class="colorhaunted">闹鬼</span>物品：',
        bra: 'Itens <span class="colorstrange">Estranhos</span> <span class="colorhaunted">Assombrados</span> desencaixotados:',
        fre: 'Objets de qualité<span class="colorstrange">Strange</span> <span class="colorhaunted">Hantée</span> déballés:',
        cze: '<span class="colorstrange">Strange</span> <span class="colorhaunted">Haunted</span> předmětů získáno:',
        hun: '<span class="colorstrange">Fura</span> <span class="colorhaunted">Kísértetjárta</span> tárgyak kibontva:',
        rom: '<span class="colorstrange">Straniu</span> <span class="colorhaunted">Bantuit</span> obiecte deschise:',
		swe: '<span class="colorstrange">Avvikande</span> <span class="colorhaunted">Hemsökt</span> föremål öppnade:',
		fil: '<span class="colorstrange">Strange</span> <span class="colorhaunted">Haunted</span> aytem nabukas:'
    },
    110: {
        eng: "Average price of an Unusual:",
        pol: "Średnia wartość Nietypowego Okazu:",
        sch: "获得稀有平均花费：",
        bra: "Preço médio de um item Incomum",
        fre: "Prix moyen d'un Inhabituel:",
        cze: "Průměrná cena Unusual předmětu:",
        hun: "Rendkívüliek átlagos értéke:",
        rom: "Pret in medie al unui obiect Neobisnuit:",
		swe: "Genomsnittspris för ett Ovanligt föremål:",
		fil: "Karaniwang presyo ng Unusual:"
    },
    111: {
        eng: "Luck",
        pol: "Szczęście",
        sch: "幸运",
        bra: "Sorte",
        fre: "Chance",
        cze: "Štěstí",
        hun: "Szerencse",
        rom: "Noroc",
		swe: "Tur",
		fil: "Swerte"
    },
    112: {
        eng: "When it comes to Unusuals, you've been:",
        pol: "Jeśli chodzi o Nietypowe Okazy, to miałeś:",
        sch: "对于稀有物品来说，您：",
        bra: "Quando chega à itens Incomuns, você esteve:",
        fre: "Quant aux Inhabituels, vous avez été:",
        cze: "Ohledně Unusual předmětů jsi měl:",
        hun: "Rendkívüliket tekintve te:",
        rom: "Cand vine vorba de obiecte Neobisnuite, tu ai fost:",
		swe: "När det kommer till Ovanliga föremål har du haft:",
		fil: "Pagdating sa Unusuals, ikaw ay naging:"
    },
    113: {
        eng: "When it comes to Stranges, you've been:",
        pol: "Jeśli chodzi o przedmioty Kuriozalne, to miałeś:",
        sch: "对于奇异物品来说，您：",
        bra: "Quando chega à itens Estranhos, você esteve:",
        fre: "Quant aux Étranges, vous avez été:",
        cze: "Ohledně Strange předmětů jsi měl:",
        hun: "Furákat tekintve te:",
        rom: "Cand vine vorba de obiecte Stranii, tu ai fost:",
		swe: "När det kommer till Avvikande föremål har du haft:",
		fil: "Pagdating sa Stranges, ikaw ay naging:"
    },
    114: {
        eng: "Lucky",
        pol: "Szczęście",
        sch: "手气不错",
        bra: "Sortudo",
        fre: "Chanceux",
        cze: "Štěstí",
        hun: "Szerencsés vagy",
        rom: "Norocos",
		swe: "Tur",
		fil: "Mapalad"
    },
    115: {
        eng: "Unlucky",
        pol: "Pech",
        sch: "运气较差",
        bra: "Azarado",
        fre: "Malchanceux",
        cze: "Smůlu",
        hun: "Balszerencsés vagy",
        rom: "Ghinionist",
		swe: "Otur",
		fil: "Malas"
    },
    116: {
        eng: "Neither lucky nor unlucky",
        pol: "Ani szczęście, ani pech",
        sch: "普普通通",
        bra: "Nem sortudo, nem azarado",
        fre: "Ni chanceux, ni malchanceux",
        cze: "Ani štěstí, ani smůlu",
        hun: "Se nem szerencsés se nem balszerencsés",
        rom: "Nici norocos, nici ghinionist",
		swe: "Varken tur eller otur",
		fil: "Hindi swerte o malas"
    },
    117: {
        eng: "Not enough data to calculate luck.<br>Come back later once you unbox more crates/cases.",
        pol: "Za mało danych do obliczenia szczęścia.<br>Wróć później jak otworzysz więcej skrzynek.",
        sch: "暂无足够数据来计算幸运。<br>开启更多箱子后再来查看。",
        bra: "Não temos muita data para calcular a sua sorte.<br>Volte quando você tiver aberto mais caixas.",
        fre: "Pas assez de données pour calculer la chance.<br>Revenez lorsque vous aurez déballé plus de caisses.",
        cze: "Nedostatek dat pro výpočet štěstí.<br>Stav se později jakmile otevřeš víc krabic/beden.",
        hun: "Nincs elég adat a szerencse kiszámításához.<br>Gyere vissza később, amikor már több ládát/táskát nyitottál ki.",
        rom: "Nu exista destula data pentru calcularea norocului.<br>Intoarce-te cand ai deschis mai multe cutii.",
		swe: "Det finns inte tillräckligt med data för att beräkna tur.<br>Kom tillbaka senare när du har öppnat fler lådor.",
		fil: "Hindi sapat ang data para ikalkulahin ang swerte.<br>Bumalik ka mamaya kapag nakabukas ka pa ng mga kahon/kaso."
    },
    118: {
        eng: "Out of #TOTALUNBOXES# unboxes that could have an Unusual,<br>you unboxed #UNUSUALSUNBOXED#, ",
        pol: "Z #TOTALUNBOXES# otwarć które mogły być Nietypowym Okazem,<br>zdobyłeś #UNUSUALSUNBOXED#, ",
        sch: "在 #TOTALUNBOXES# 个有机会开出稀有物品的箱子中，<br>您开出了 #UNUSUALSUNBOXED# 个稀有物品,",
        bra: "De #TOTALUNBOXES# de caixas que você abriu que poderiam ter um item Incomum,<br>você abriu #UNUSUALSUNBOXED#, ",
        fre: "Sur les #TOTALUNBOXES# caisses qui auraient pu être Inhabituelles,<br>vous avez déballé #UNUSUALSUNBOXED#, ",
        cze: "Z celkových #TOTALUNBOXES# otevření, co mohly být Unusual kvality,<br>jsi otevřel #UNUSUALSUNBOXED#, ",
        hun: "A #TOTALUNBOXES# kibontásból ami lehetett Rendkívüli,<br> kinyitottál #UNUSUALSUNBOXED#-t, ",
        rom: "Din atatea #TOTALUNBOXES# deschideri care au fi putut fi un obiect Neobisnuit,<br>tu ai deschis #UNUSUALSUNBOXED#, ",
		swe: "Ut av #TOTALUNBOXES# lådor som kunde ha ett Ovanlig föremål,<br>fick du #UNUSUALSUNBOXED# stycken, ",
		fil: "Mula sa #TOTALUNBOXES# nabukas na maaring magkaroon ng Unusual,<br>nabukas mo #UNUSUALSUNBOXED#, "
    },
    119: {
        eng: "which is #DIFF# more than we predicted (#PREDICTION#).",
        pol: "czyli #DIFF# więcej niż się spodziewaliśmy (#PREDICTION#).",
        sch: "比我们预测的（#PREDICTION#）要多 #DIFF# 个。",
        bra: "que é #DIFF# mais do que a nossa predição (#PREDICTION#).",
        fre: "ce qui est #DIFF# plus que nous avons prédit (#PREDICTION#).",
        cze: "což je o #DIFF# víc, než jsme předpokládali (#PREDICTION#).",
        hun: "ami #DIFF#-al/el több, mint amennyire számítani lehetett (#PREDICTION#).",
        rom: "ceea ce este #DIFF# mai mult decat am prezis (#PREDICTION#).",
		swe: "vilket är #DIFF# mer än vi förutspådde (#PREDICTION#).",
		fil: "ay #DIFF# masmari na sa predikto namin (#PREDICTION#)."
    },
    120: {
        eng: "which is #DIFF# less than we predicted (#PREDICTION#).",
        pol: "czyli #DIFF# mniej niż się spodziewaliśmy (#PREDICTION#).",
        sch: "比我们预测的（#PREDICTION#）要少 #DIFF# 个。",
        bra: "que é #DIFF# menos do que a nossa predição (#PREDICTION#).",
        fre: "ce qui est #DIFF# moins que nous avons prédit (#PREDICTION#).",
        cze: "což je o #DIFF# méně, než jsme předpokládali (#PREDICTION#).",
        hun: "ami #DIFF#-al/el kevesebb, mint amennyire számítani lehetett (#PREDICTION#).",
        rom: "ceea ce este #DIFF# mai putin decat am prezis (#PREDICTION#).",
		swe: "vilket är #DIFF# mindre än vi förutspådde (#PREDICTION#).",
		fil: "ay #DIFF# masmababa sa predikto namin (#PREDICTION#)."
    },
    121: {
        eng: "same amount as our prediction.",
        pol: "zgodnie z przewidywaniami.",
        sch: "符合我们预测的数量。",
        bra: "mesmo valor que a nossa predição.",
        fre: "le même montant que nous avons prédit.",
        cze: "stejná hodnota jako naše predikce.",
        hun: "pontosan annyi, amennyire számítani lehetett.",
        rom: "ceea ce este aceeasi suma pe care am prezis-o.",
		swe: "samma som vi förutspådde.",
		fil: "kaparehong halaga sa predikto namin."
    },
    122: {
        eng: "Out of #TOTALUNBOXES# unboxes that could have a Strange item,<br>you unboxed #STRANGESUNBOXED#, ",
        pol: "Z #TOTALUNBOXES# otwarć które mogły być przedmiotem Kuriozalnym,<br>zdobyłeś #STRANGESUNBOXED#, ",
        sch: "在 #TOTALUNBOXES# 个有机会开出奇异物品的箱子中，<br>您开出了 #STRANGESUNBOXED# 个奇异物品，",
        bra: "De #TOTALUNBOXES# de caixas que você abriu que poderiam ter um item Estranho,<br>você abriu #STRANGESUNBOXED#, ",
        fre: "Sur les #TOTALUNBOXES# caisses qui auraient pu être Étranges,<br>vous avez déballé #STRANGESUNBOXED#,",
        cze: "Z celkových #TOTALUNBOXES# otevření, co mohly být Strange kvality,<br>jsi otevřel #STRANGESUNBOXED#, ",
        hun: "A #TOTALUNBOXES# kibontásból ami lehetett Fura tárgy,<br> kinyitottál #STRANGESUNBOXED#-t, ",
        rom: "Din atatea #TOTALUNBOXES# deschideri care au fi putut fi un obiect Straniu,<br>tu ai deschis #STRANGESUNBOXED#, ",
		swe: "Ut av #TOTALUNBOXES# lådor som kunde ha ett Ovanlig föremål,<br>fick du #STRANGESUNBOXED# stycken, ",
		fil: "Mula sa #TOTALUNBOXES# nabukas na maaring merong Strange aytem,<br>and nabukas mo #STRANGESUNBOXED#, "
    },
    123: {
        eng: "Cancel",
        pol: "Anuluj",
        sch: "取消",
        bra: "Cancelar",
        fre: "Annuler",
        cze: "Zrušit",
        hun: "Mégse",
        rom: "Anulare",
		swe: "Avbryt",
		fil: "Ikansel"
    },
    124: {
        eng: "This may take a few seconds.<br>Please be patient.",
        pol: "To może potrwać chwilę.<br>Proszę o cierpliwość.",
        sch: "这可能要花几秒来完成。<br>请稍候。",
        bra: "Isso pode demorar alguns segundos.<br>Por favor tenha paciência.",
        fre: "Ceci pourrait prendre quelques secondes.<br>Veuillez patienter.",
        cze: "Tohle chvilku potrvá.<br>Prosím, počkej.",
        hun: "Ez eltarthat pár másodpercig.<br>Kérlek légy türelmes.",
        rom: "Asta va dura cateva secunda.<br>Va rugam sa aveti rabdare.",
		swe: "Det kan ta några sekunder.<br>Ha tålamod.",
		fil: "Ito ay maaring tumagal ng kakaunting sigundo.<br>Please humintay ka."
    },
    125: {
        eng: "This will take a minute or two to process.<br>Please be patient.",
        pol: "To może potrwać minutę lub dwie.<br>Proszę o cierpliwość.",
        sch: "这将需要几分钟来完成。<br>请稍候。",
        bra: "Isso pode demorar um minuto ou dois para processar.<br>Por favor tenha paciência.",
        fre: "Ceci prendra une minute ou deux à procéder.<br>Veuillez patienter.",
        cze: "Tohle zabere chvilku na zpracování.<br>Prosím, buď trpělivý.",
        hun: "Ez eltarthat pár percig.<br>Kérlek légy türelmes.",
        rom: "Asta va dura un minut sau doua pentru a se procesa.<br>Va rugam sa aveti rabdare.",
		swe: "Det tar en minut eller två att bearbeta.<br>Ha tålamod.",
		fil: "Ito ay tatagal ng isa o dalawang minuto para i-proses.<br>Please humintay ka."
    },
    126: {
        eng: "Sheen:",
        pol: "Połysk",
        sch: "武器光泽特效：",
        bra: "Brilho:",
        fre: "Éclat:",
        cze: "Lesk:",
        hun: "Ragyogás:",
        rom: "Luciu:",
		swe: "Glans:"
    },
    127: {
        eng: "Killstreaker:",
        pol: "Seryjny Zabójca:",
        sch: "连杀者特效：",
        bra: "Efeito de combo:",
        fre: "Killstreak:",
        cze: "Efekt při sérii zabití:",
        hun: "Ámokfutó:",
        rom: "Omor Consecutiv:",
		swe: "Killstreaker:"
    },
    128: {
        eng: "Team Shine",
        pol: "blask drużyny",
        sch: "团队之光",
        bra: "Brilho da equipe",
        fre: "Éclat d'équipe",
        cze: "Team Shine",
        hun: "Csapat-ragyogás",
        rom: "Luciu de Echipa",
		swe: "Lag Glans"
    },
    129: {
        eng: "Hot Rod",
        pol: "hot rod",
        sch: "赤红新星",
        bra: "Rajada de roxo",
        fre: "Éclat de chaleur",
        cze: "Hot Rod",
        hun: "Ívadó",
        rom: "Tija Fierbinte",
		swe: "Hot Rod"
    },
    130: {
        eng: "Manndarin",
        pol: "manndarynka",
        sch: "众志成橙",
        bra: "Manndarin",
        fre: "Manndarine",
        cze: "Manndarin",
        hun: "Manndarin",
        rom: "Mandarina",
		swe: "Manndarin"
    },
    131: {
        eng: "Deadly Daffodil",
        pol: "nieludzki narcyz",
        sch: "致命水仙",
        bra: "Narciso nefasto",
        fre: "Jonquille mortelle",
        cze: "Deadly Daffodil",
        hun: "Halálos Nárcisz",
        rom: "Narcisa Mortala",
		swe: "Dödliga Påsklilja"
    },
    132: {
        eng: "Agonizing Emerald",
        pol: "bolesny szmaragd",
        sch: "苦难翡翠",
        bra: "Esmeralda agonizante",
        fre: "Émeraude agonisant",
        cze: "Agonizing Emerald",
        hun: "Gyötrelmes Smaragd",
        rom: "Emerald Agonizant",
		swe: "Plågsamma Smaragd"
    },
    133: {
        eng: "Mean Green",
        pol: "złośliwa zieleń",
        sch: "绿行绿素",
        bra: "Lima maligna",
        fre: "Méchamment vert",
        cze: "Mean Green",
        hun: "Durva Zöld",
        rom: "Verde Rautacios",
		swe: "Snåla Grön"
    },
    134: {
        eng: "Villainous Violet",
        pol: "perfidna purpura",
        sch: "罪恶紫罗兰",
        bra: "Violeta vil",
        fre: "Vil violet",
        cze: "Villainous Violet",
        hun: "Aljas Lila",
        rom: "Violet Raufacator",
		swe: "Skurklig Violett"
    },
    135: {
        eng: "Cerebral Discharge",
        pol: "mózgowe wyładowanie",
        sch: "大脑放电",
        bra: "Descarga cerebral",
        fre: "Décharge cérébrale",
        cze: "Cerebral Discharge",
        hun: "Agyi Kisülés",
        rom: "Descarcare Cerebrala",
		swe: "Cerebral Urladdning"
    },
    136: {
        eng: "Fire Horns",
        pol: "ogniste rogi",
        sch: "火焰号角",
        bra: "Chifres ardentes",
        fre: "Cornes de feu",
        cze: "Fire Horns",
        hun: "Tüzes Szarvak",
        rom: "Coarne de Foc",
		swe: "Eld Horn"
    },
    137: {
        eng: "Flames",
        pol: "płomienie",
        sch: "火焰",
        bra: "Chamas",
        fre: "Flammes",
        cze: "Flames",
        hun: "Lángok",
        rom: "Flacari",
		swe: "Flammor"
    },
    138: {
        eng: "Hypno-Beam",
        pol: "hipno-promień",
        sch: "催眠光束",
        bra: "Raio hipnotizante",
        fre: "Rayon hypnotique",
        cze: "Hypno-Beam",
        hun: "Hipno-nyaláb",
        rom: "Raza-Hipnotica",
		swe: "Hypno-Stråle"
    },
    139: {
        eng: "Incinerator",
        pol: "spopielacz",
        sch: "焚化炉",
        bra: "Incinerador",
        fre: "Incinérateur",
        cze: "Incinerator",
        hun: "Krematórium",
        rom: "Incinerator",
		swe: "Förbränning"
    },
    140: {
        eng: "Singularity",
        pol: "osobliwość",
        sch: "奇点",
        bra: "Singularidade",
        fre: "Singularité",
        cze: "Singularity",
        hun: "Szingularitás",
        rom: "Singularitate",
		swe: "Säregenhet"
    },
    141: {
        eng: "Tornado",
        pol: "tornado",
        sch: "飓风",
        bra: "Tornado",
        fre: "Tornade",
        cze: "Tornado",
        hun: "Tornádó",
        rom: "Tornada",
		swe: "Tornado"
    },
    142: {
        eng: "Killstreak Kits obtained from this crate may be unboxed as Specialized or Professional Killstreak Kits instead.",
        pol: "Zestawy z serią zabójstw z tej skrzynki mogą zostać zastąpione zestawami z wyspecjalizowaną lub profesionalną serią zabójstw.",
        sch: "从这个箱子中获得的连杀记录工具包可能为高级或职业品质。",
        bra: "Kits de Combo obtidos por essa caixa podem ser desencaixotados como Kits de Combo Especializados ou Kits de Combo Profissionais.",
        fre: "Les kits Killstreak obtenus de cette caisse peuvent être Spécialisés ou Professionels plutôt qu'Unique.",
        cze: "Kity z této krabice mohou být verze Specialized nebo Professional.",
        hun: "Az Ámokfutás készletek ebből a ládából lehetnek Specializált vagy Hivatásos Ámokfutás Készleteket.",
        rom: "Trusele de Omoruri Consecutive obtinute din aceasta cutie pot fi deschise ca de Specialitate sau Truse Profesionale de Omor Consecutiv.",
		swe: "Killstreak-kit som öppnas från denna lådan kan i stället vara öppnade som specialiserade eller professionella Killstreak-kit.",
		fil: "Killstreak Kits na makukuha sa kahon nito ay mabubuksan na Specialized o Professional Killstreak Kits sa halip."
    },
    143: {
        eng: "Bonus items unboxed:",
        pol: "Otrzymane przedmioty dodatkowe:",
        sch: "已开出奖励物品：",
        bra: "Itens de bônus desencaixotados:",
        fre: "Objets bonis déballés:",
        cze: "Získáno bonusových předmětů:",
        hun: "Bónusztárgyak kibontva:",
        rom: "Obiecte bonus deschise:",
		swe: "Bonus föremål öppnade:",
		fil: "Bonus aytem nabukas:"
    },
    144: {
        eng: "Bonus items",
        pol: "Dodatkowe przedmioty",
        sch: "奖励物品",
        bra: "Itens de bônus",
        fre: "Objets bonis",
        cze: "Bonusové předměty",
        hun: "Bónusztárgy",
        rom: "Obiecte bonus deschise",
		swe: "Bonusföremål",
		fil: "Bonus aytem"
    },
    145: {
        eng: "None",
        pol: "Brak",
        sch: "无",
        bra: "Nenhum",
        fre: "Rien",
        cze: "Žádné",
        hun: "Semmi",
        rom: "Niciunul",
		swe: "Inga",
		fil: "Wala"
    },
    146: {
        eng: "Unique",
        pol: "Unikalny",
        sch: "独特",
        bra: "Único",
        fre: "Unique",
        cze: "Unique",
        hun: "Egyedi",
        rom: "Unic",
		swe: "Unik"
    },
    147: {
        eng: "This case may contain bonus items.",
        pol: "Ta skrzynka może zawierać dodatkowe przedmioty.",
        sch: "这个箱子可能包含奖励物品。",
        bra: "Essa caixa pode conter itens de bônus.",
        fre: "Cette caisse peut contenir des objets bonis.",
        cze: "Tato bedna může obsahovat bonusové předměty.",
        hun: "Ebben a táskában bónusztárgyak lehetnek.",
        rom: "Aceasta cutie poate contine obiecte bonus.",
		swe: "Detta låda kan innehålla bonusföremål.",
		fil: "Itong kaso ay maaring merong bonus aytem."
    },
    148: {
        eng: "Crate/case-exclusive bonus items:",
        pol: "Dodatkowe przedmioty ekskluzywne dla tej skrzynki:",
        sch: "箱子特有奖励物品：",
        bra: "Itens exclusivos para essa caixa:",
        fre: "Objets exclusifs de cette caisse:",
        cze: "Bonusové předměty exkluzivně dle krabice/bedny",
        hun: "Láda/táska-exkluzív bónusztárgyak:",
        rom: "Obiect bonus exclusiv cutiei:",
		swe: "Bonusföremål exclusivt för lådan:",
		fil: "Kahon/kaso-ekslusibong bonus aytem:"
    },
    149: {
        eng: "Stop unboxing at certain items:",
        pol: "Przerwij otwieranie po otrzymaniu specyficznych przedmiotów:",
        sch: "开出特定物品时停止开箱：",
        bra: "Pare de desencaixotar em certos itens:",
        fre: "Arrêter de déballer à certains objets:",
        cze: "Pozastavit rozbalování na určitých předmětech:",
        hun: "Kibontás megállítása bizonyos tárgyaknál:",
        rom: "Nu mai deschide la anumite obiecte:",
		swe: "Sluta öppna vid vissa objekt:",
		fil: "Tumigil bumukas sa tiyak na aytem:"
    },
    150: {
        eng: "This will prevent you from accidentally unboxing another crate/case after getting certain items.",
        pol: "Ta opcja tymczasowo przerwie otwieranie po otrzymaniu wybranych przedmiotów.",
        sch: "此选项防止您在开出特定物品后意外开启另一个箱子。",
        bra: "Isso vai prevenir você de acidentalmente desencaixotar outra caixa depois de obter certos itens.",
        fre: "Ceci vous empêche d'accidentellement déballer une autre caisse après l'obtention de certains objets.",
        cze: "Tato možnost na moment pozastaví otevírání beden po obdržení určitých předmětů.",
        hun: "Ez megakadályozza, hogy véletlenül kibonts egy újabb ládát/táskát egy bizonyos tárgy kibontása után.",
        rom: "Aceasta te va opri din a deschide din greseala o cutie dupa ce ai primit anumite obiecte.",
		swe: "Detta kommer att förhindra att du av misstag öppnar en till låda efter att ha fått vissa föremål.",
		fil: "Ito ay ipipigilan ka sa pagaksidenteng pagbukas ng isa pang kahon/kaso pagtapos makuha ng tiyak na aytem."
    },
    151: {
        eng: "Items",
        pol: "Przedmioty",
        sch: "物品",
        bra: "Itens",
        fre: "Objets",
        cze: "Předměty",
        hun: "Tárgyak",
        rom: "Obiecte",
		swe: "Föremål",
		fil: "Aytem"
    },
    152: {
        eng: "Page #PAGE# of #TOTAL#",
        pol: "Strona #PAGE# z #TOTAL#",
        sch: "第 #PAGE# / #TOTAL# 页",
        bra: "Página #PAGE# de #TOTAL#",
        fre: "Page #PAGE# de #TOTAL#",
        cze: "Strana #PAGE# z #TOTAL#",
        hun: "Oldal #PAGE# a #TOTAL#-ból/ből",
        rom: "Pagina #PAGE# din #TOTAL#",
		swe: "Sida #PAGE# av #TOTAL#",
		fil: "Pahina #PAGE ng #TOTAL#"
    },
    153: {
        eng: "Single bonus items unboxed:",
        pol: "Otrzymane pojedyncze przedmioty dodatkowe:",
        sch: "开出一个奖励物品的次数：",
        bra: "Singelos itens de bônus desencaixotados:",
        fre: "Objets bonis singuliers déballés:",
        cze: "Rozbaleno jednotlivých bonusových předmětů:",
        hun: "Szimpla bónusztárgyak kibontva:",
        rom: "Singurele obiecte bonus deschise:",
		swe: "Enstaka bonusföremål öppnade:",
		fil: "Isang bonus na aytem nabukas:"
    },
    154: {
        eng: "Double bonus items unboxed:",
        pol: "Otrzymane podwójne przedmioty dodatkowe:",
        sch: "开出两个奖励物品的次数：",
        bra: "Duplos itens de bônus desencaixotados:",
        fre: "Objets bonis doubles déballés:",
        cze: "Rozbaleno dvojnásobných bonusových předmětů:",
        hun: "Dupla bónusztárgyak kibontva:",
        rom: "Obiecte bonus duble deschise:",
		swe: "Dubbel-bonusföremål öppnade:",
		fil: "Dalawang bonus na aytem nabukas:"
    },
    155: {
        eng: "Triple bonus items unboxed:",
        pol: "Otrzymane potrójne przedmioty dodatkowe:",
        sch: "开出三个奖励物品的次数：",
        bra: "Triplos itens de bônus desencaixotados:",
        fre: "Objets bonis triples déballés:",
        cze: "Rozbaleno trojnásobných bonusových předmětů:",
        hun: "Tripla bónusztárgyak kibontva:",
        rom: "Obiecte bonus triple deschise:",
		swe: "Trippel-bonusföremål öppnade:",
		fil: "Tatlong bonus na aytem nabukas:"
    },
    156: {
        eng: "Unusualifiers unboxed:",
        pol: "Otrzymane uniezwyklacze:",
        sch: "开出稀有转化魔法：",
        bra: "Incomunizadores desencaixotados:",
        fre: "Inhabituellisateurs déballés:",
        cze: "Rozbaleno Unusualifierů:",
        hun: "Rendkívüliesítők kibontva:",
        rom: "Neobisnuitoare deschise:",
		swe: "Ovanlighetsgörare öppnade:",
		fil: "Unusualifiers nabukas:"
    },
    157: {
        eng: "Strange Stat Clock Attached",
        pol: "Dołączony licznik zabójstw",
        sch: "已附上统计记录表",
        bra: "Contador Estranho de estatísticas anexado",
        fre: "Compeur de statistiques Étrange attaché",
        cze: "Má přidělaný modul pro záznam (Strange)",
        hun: "Fura statisztikaszámláló csatlakoztatva",
        rom: "Ceas Straniu de Statistici atasat",
		swe: "Avvikande Statsklocka Bifogad"
    },
    158: {
        eng: "Unusualifiers",
        pol: "Uniezwyklacze",
        sch: "稀有转化魔法",
        bra: "Incomunizadores",
        fre: "Inhabituellisateurs",
        cze: "Unusualifiery",
        hun: "Rendkívüliesítők",
        rom: "Neobisnuitoare",
		swe: "Ovanlighetsgörare"
    },
    159: {
        eng: "Stranges",
        pol: "Przedmioty kuriozalne",
        sch: "奇异",
        bra: "Estranhos",
        fre: "Étranges",
        cze: "Strange předměty",
        hun: "Furák",
        rom: "Obiecte stranii",
		swe: "Avvikande"
    },
    160: {
        eng: "Grade",
        pol: "Stopień",
        sch: "等级",
        bra: "Nível",
        fre: "Grade",
        cze: "Třída",
        hun: "Fokozat",
        rom: "Grad",
		swe: "Grad",
		fil: "Grado"
    },
    161: {
        eng: "Prof. killstreak kits",
        pol: "Zestawy z prof. serią zabójstw",
        sch: "职业连杀记录工具包",
        bra: "Kits de Combo Profissionais",
        fre: "Kits killstreak pro.",
        cze: "Prof. killstreak kity",
        hun: "Hiv. ámokfutás készlet",
        rom: "Truse Profesionale de Omoruri Consecutive",
		swe: "Prof. killstreak-kit"
    },
    162: {
        eng: "Triple bonus items",
        pol: "Potrójne przedmioty dodatkowe",
        sch: "三个奖励物品",
        bra: "Triplos itens de bônus",
        fre: "Objets bonis triples",
        cze: "Trojnásobný bonusový předmět",
        hun: "Tripla bónusztárgyak",
        rom: "Obiecte bonus triple",
		swe: "Trippel-bonusföremål",
		fil: "Tripleng bonus aytem"
    },
    163: {
        eng: "Grade Mercenary",
        pol: "Stopień Najemnik",
        sch: "雇佣级",
        bra: "Nível Mercenário",
        fre: "Grade Mercenaire",
        cze: "Třída Mercenary",
        hun: "Zsoldos Fokozat",
        rom: "Grad de Mercenar",
		swe: "Legosoldats Grad",
		fil: "Gradong Mercenary"
    },
    164: {
        eng: "Grade Commando",
        pol: "Stopień Komandos",
        sch: "特种级",
        bra: "Nível Comando",
        fre: "Grade Commando",
        cze: "Třída Commando",
        hun: "Kommandós Fokozat",
        rom: "Grad de Comando",
		swe: "Kommando Grad",
		fil: "Gradong Commando"
    },
    165: {
        eng: "Grade Assassin",
        pol: "Stopień Asasyn",
        sch: "刺杀级",
        bra: "Nível Assassino",
        fre: "Grade Assassin",
        cze: "Třída Assassin",
        hun: "Orgyilkos Fokozat",
        rom: "Grad de Asasain",
		swe: "Lönnmördar Grad",
		fil: "Gradong Assassin"
    },
    166: {
        eng: "Grade Elite",
        pol: "Stopień Elita",
        sch: "精英级",
        bra: "Nível Elite",
        fre: "Grade Élite",
        cze: "Třída Elite",
        hun: "Elit Fokozat",
        rom: "Grad de Elită",
		swe: "Elit Grad",
		fil: "Gradong Elite"
    },
    167: {
        eng: "Mercenary",
        pol: "Najemnik",
        sch: "雇佣",
        bra: "Mercenário",
        fre: "Mercenaire",
        cze: "Mercenary",
        hun: "Zsoldos",
        rom: "Mercenar",
		swe: "Legosoldat"
    },
    168: {
        eng: "Commando",
        pol: "Komandos",
        sch: "特种",
        bra: "Comando",
        fre: "Commando",
        cze: "Commando",
        hun: "Kommandós",
        rom: "Comando",
		swe: "Kommando"
    },
    169: {
        eng: "Assassin",
        pol: "Asasyn",
        sch: "刺杀",
        bra: "Assassino",
        fre: "Assassin",
        cze: "Assassin",
        hun: "Orgyilkos",
        rom: "Asasin",
		swe: "Lönnmördar"
    },
    170: {
        eng: "Elite",
        pol: "Elita",
        sch: "精英",
        bra: "Elite",
        fre: "Élite",
        cze: "Elite",
        hun: "Elit",
        rom: "Elită",
		swe: "Elit"
    },
    171: {
        eng: "Bulk unboxing in progress...",
        pol: "Trwa otwieranie wielu skrzynek...",
        sch: "正在批量开箱...",
        bra: "Desencaixotamento em massa em andamento...",
        fre: "Déballage de masse en cours...",
        cze: "Rozbalování hromadného obsahu...",
        hun: "Nagytételű kibontás folyamatban...",
        rom: "Deschidere in masa in progres...",
		swe: "Massöppning pågår ...",
		fil: "Maramihang pagbukas na sa progreso..."
    },
    172: {
        eng: "Bulk unboxing results",
        pol: "Wyniki zbiorczego otwierania",
        sch: "批量开箱结果",
        bra: "Resultados de desencaixotamento em massa",
        fre: "Résultats du déballage de masse",
        cze: "Výsledky hromadného rozbalování",
        hun: "Nagytételű kibontás eredményei",
        rom: "Deschidere in masa rezultate",
		swe: "Massöppning resultat",
		fil: "Resulto ng maramihang pagbukas "
    },
    173: {
        eng: "Enable EOTL glitched Unusuals:",
        pol: "Włącz zglitchowane Nietypowe Okazy EOTL:",
        sch: "启用 EOTL 异常稀有物品：",
        bra: "Ativar itens Incomuns falhados do EOTL:",
        fre: "Activer les Inhabituels buggés EOTL:",
        cze: "Povoluje EOTL glitchnuté Unusual předměty:",
        hun: "Végállomás Frissítés hibás Rendkívüliek engedélyezése:",
        rom: "Porneste EOTL obiecte Neobisnuite buguite",
		swe: "Aktivera EOTL glitchade Ovanliga föremål:",
		fil: "Ibukas ang EOTL glitched Unusuals:"
    },
    174: {
        eng: 'Allows the Hunter Heavy, Coldfront Curbstompers and Sleeveless in Siberia to be unboxed in Unusual quality from the End of the Line Community Crate.',
        pol: 'Pozwala na otrzymanie "Łowcy grubego zwierza", "Zimowych zgniataczy" i "Syberyjskiego bezrękawnika" jako Nietypowych Okazów z skrzynki aktualizacji społeczności „Koniec trasy”.',
        sch: '允许在 End of the Line 社区补给箱中开出稀有品质的“机枪猎手”、“冷锋践踏者”和“西伯利亚无袖衣”。',
        bra: 'Permite que os cosméticos "Caçador Camuflado", "Frente Quente" e "Jaqueta de Geada" sejam desencaixotados em qualidade Incomum por a caixa do End of the Line.',
        fre: 'Autoriser le Heavy Chasseur, les Mange-trottoir de Coldfront ainsi que le Débardeur Sibérien à être déballé en qualité Inhabituelles de la caisse End of the Line.',
        cze: 'Umožňuje předměty the Hunter Heavy, Coldfront Curbstompers a Sleeveless in Siberia rozbalit v Unusual kvalitě z krabice z aktualizace End of the Line.',
        hun: 'Engedélyezi a Nehéz Vadászt, Hidegfront-harcost és a Szibériai Ujjatlant kibontani Rendkívüli fokozatban a Végállomás Közösségi Ládából.',
        rom: 'Permite pe vantatorul Heavy, Coldfront Curbstompers si Fara Maneci in Siberia sa fie deschis in calitate Neobisnuita din cutia de comunitate Sfarsitul Liniei.',
		swe: 'Tillåter att Jägar Heavy, Kallfrpnt Trottoarstampare och Ärmlös i Sibirien får öppnas i Ovanlig kvalitet från End of the Line Samhällslåda.',
		fil: 'Pinapayagan ang Hunter Heavy,Coldfront Curbstompers at Sleeveless sa Siberia para mabukas sa Unusual na kalidad sa End of the Line Community kahon.'
    },
    175: {
        eng: "Event mode:",
        pol: "Tryb wydarzenia:",
        sch: "活动模式：",
        bra: "Modo de Evento:",
        fre: "Mode événement:",
        cze: "Režim událostí:",
        hun: "Esemény mód:",
        rom: "Mod de Eveniment:",
		swe: "Eventläge:"
    },
    176: {
        eng: "This option replaces Unusual effects in certain crates/cases with effects from the selected event.<br>Events from 2011 to 2016 affect only crates and cases released before the event.<br>Events from 2017 onwards affect only cases released before the event.",
        pol: "Ta opcja zmienia efekty Nietypowych Okazów w niektórych skrzynkach na efekty z wybranego wydarzenia.<br>Wydarzenia z 2011 do 2016 roku obejmują tylko skrzynki wydane przed wydarzeniem.<br>Wydarzenia z 2017 i następnych lat obejmują tylko skrzynki wydane przed wydarzeniem i po aktualizacji Siła charakteru.",
        sch: "此选项将特定箱子的稀有效果替换为指定活动的效果。<br>2011 至 2016 年的活动只会影响活动之前发布的箱子。<br>2017 年起的活动只会影响活动之前与枪魂更新之后发布的箱子。",
        bra: "Essa opção substitui efeitos Incomuns em certas caixas com efeitos do evento selecionado.<br>Eventos de 2011 para 2016 só afetam caixas lançadas antes do evento.<br>Eventos de 2017 e avante só afetam as caixas lançadas antes do evento e as caixas lançadas depois da atualização Arsenal Artístico.",
        fre: "Cette option remplace les effets Inhabituels pour certaines caisses selon l'événement choisi.<br>Les Événements de 2011 jusqu'à 2016 n'affectent que les caisses sorties avant l'update Gun Mettle. Les Événements à partir de 2017 n'affectent que les caisses sorties avant l'événement and celles sorties depuis l'update Gun Mettle.",
        cze: "Toto nastavení vám umožňuje nahradit Unusual efekty u některých beden z vybraných událostí.<br>Události z roku 2011-2016 mají vliv pouze na bedny vydané před aktualizací Gun Mettle.<br>Události od roku 2017 ovlivňují pouze krabice a bedny vydané před událostí a ty, které byly vydány z aktualizace Gun Mettle.",
        hun: "Ez az opció kiváltja a Rendkívüli effekteket bizonyos ládákba/táskákban a kiválasztott eseményről.<br>A 2011 és 2016 közötti események csak az azt megelőző években megjelent ládákat és táskákat befolyásolja.<br>A 2017 és utáni események csak az adott eseményt megelőző táskákat befolyásolja.",
        rom: "Aceasta optiune inlocuieste efectele neobisnuite in anumite cutii cu efecte din evenimentul selectat.<br>Evenimentele din 2011 pana in 2016 afecteaza doar cutiile lansate inainte de eveniment.<br>Evenimente din 2017 in sus afecteaza doar cutiile lansate dupa eveniment.",
		swe: "Detta alternativ ersätter Ovanliga effekter i vissa lådor/fodral med effekter från det valda eventet.<br>Eventer från 2011 till 2016 påverkar endast lådor som släppts före eventet.<br>Events från och med 2017 påverkar endast fodral som släppts före eventet och efter Gun Mettle-uppdateringen.",
		fil: "Itong opsyon ay ipapalit Unusual epekto  sa tiyak na mga kahon/kaso at kasamang epekto sa napiling event.<br>Events galing sa 2011 hanggang 2016 ay nakakaapekto lang ang mga kahon at kaso na nilabas bago ang event.<br>Mga events mula 2017 pasulong ay nakakaapekto lang kaso nilabas bago ang event."
    },
    177: {
        eng: "Disabled",
        pol: "Wyłączone",
        sch: "禁用",
        bra: "Desativado",
        fre: "Désactivé",
        cze: "Vypnuto",
        hun: "Kikapcsolva",
        rom: "Dezactivat",
		swe: "Inaktiverad",
		fil: "Nakapatay"
    },
    178: {
        eng: "Very Scary Halloween Special (2011)",
        pol: "Bardzo Straszne Halloween (2011)",
        sch: "非常恐怖的万圣节特约（2011）",
        bra: "Especial Bem Assustador de Dia das Bruxas (2011)",
        fre: "Spécial Very Scary Halloween (2011)",
        hun: "Nagyon Ijesztő Halloween Különkiadás (2011)",
        rom: "Special de Infricosatorul Halloween (2011)",
		swe: "Mycket Läskig Halloween Special (2011)"
    },
    179: {
        eng: "Spectral Halloween Special (2012)",
        pol: "Spektralne Halloween (2012)",
        sch: "幽灵万圣节特约（2012）",
        bra: "Especial Espectral de Dia das Bruxas (2012)",
        fre: "Spécial Halloween Spectral (2012)",
        hun: "Kísérteties Halloween Különkiadás (2012)",
        rom: "Special de Halloween Fantomatic (2012)",
		swe: "Spektral Halloween Special (2012)"
    },
    180: {
        eng: "Scream Fortress V (2013)",
        sch: "尖叫要塞 V（2013）",
        rom: "Fortareata Urlatoarea V (2013)",
		swe: "Skrik Fortress V (2013)"
    },
    181: {
        eng: "Scream Fortress VI (2014)",
        sch: "尖叫要塞 VI（2014）",
        rom: "Fortareata Urlatoare VI (2014)",
		swe: "Skrik Fortress VI (2014)"
    },
    182: {
        eng: "Scream Fortress VIII (2016)",
        sch: "尖叫要塞 VIII（2016）",
        rom: "Fortareata Urlatoare VIII (2016)",
		swe: "Skrik Fortress VIII (2016)"
    },
    183: {
        eng: "Scream Fortress IX (2017)",
        sch: "尖叫要塞 IX（2017）",
        rom: "Fortareata Urlatoare IX (2017)",
		swe: "Skrik Fortress IX (2017)"
    },
    184: {
        eng: "Scream Fortress X (2018)",
        sch: "尖叫要塞 X（2018）",
        rom: "Fortareata Urlatoare X (2018)",
		swe: "Skrik Fortress X (2018)"
    },
    185: {
        eng: "Scream Fortress XI (2019)",
        sch: "尖叫要塞 XI（2019）",
        rom: "Fortareata Urlatoare XI (2019)",
		swe: "Skrik Fortress XI (2019)"
    },
    186: {
        eng: "Scream Fortress XII (2020)",
        sch: "尖叫要塞 XII（2020）",
        rom: "Fortareata Urlatoare XII (2020)",
		swe: "Skrik Fortress XII (2020)"
    },
    187: {
        eng: "Support Unboxer.tf by donating",
        pol: "Wspomóż Unboxer.tf, przekazując darowiznę",
        sch: "捐赠以支持 Unboxer.tf",
        bra: "Apoie Unboxer.tf doando",
        fre: "Supportez Unboxer.tf en faisant un don",
        cze: "Podpoř Unboxer.tf příspěvkem",
        hun: "Támogasd az Unboxer.tf oldalt adományokkal",
        rom: "Sustine Unboxer.tf prin a dona",
		swe: "Stöd Unboxer.tf genom att donera",
		fil: "I-support Unboxer.tf sa pamimigitan ng donating"
    },
    188: {
        eng: "Unboxer.tf was made almost entirely by a single person in his spare time. If you like the website, please consider donating.<br>For your convenience, I do not and never will run any ads on this site.<br>Even a small donation will encourage me to continue working on Unboxer.tf. Thank you!",
        pol: "Unboxer.tf zostało stworzone prawie całkowicie przez jedną osobę w jego wolnym czasie. Jeśli spodobała ci się ta strona, proszę o przekazanie darowizny.<br>Dla Twojej wygody, nie ma i nie będzie żadnych reklam na stronie.<br>Nawet mała darowizna zmotywuje mnie do dalszej pracy nad Unboxer.tf. Dziękuję!",
        sch: "Unboxer.tf 仅由一名开发者在空闲时间开发而成。如果您喜欢此网站，请考虑捐赠。<br>即使是一点点赠予也会鼓励我继续开发 Unboxer.tf。谢谢！",
        bra: "Unboxer.tf foi feito quase inteiramente por uma só pessoa em seu tempo livre. Se você gostou do website, por favor considere doar.<br>Até uma doação pequena me incentiva a continuar trabalhando no Unboxer.tf. Obrigado!",
        fre: "Unboxer.tf n'a été fabriqué que par une seule personne dans ses temps libre. Si vous aimez ce site, veuillez considérer un don.<br>Même les petit montants m'encouragent à continuer de travailler sur Unboxer.tf. Merci!",
        cze: "Unboxer.tf byl skoro celý vytvořen pouze jedním člověkem během jeho volného času. Pokud se vám stránka líbí, zvažte případný příspěvěk.<br>I malý dar mě povzbudí k další práci na Unboxer.tf. Děkuji!",
        hun: "Az Unboxer.tf-et teljes egészében egy ember készítette a szabadidejében. Ha tetszik az oldal, kérlek fontold meg, hogy adományozz.<br>Minden kicsi adomány is ösztönöz arra, hogy továbbra is dolgozzak az Unboxer.tf-en. Köszönöm!",
        rom: "Unboxer.tf a fost facut aproape complet de o singura persoana in propriul timp liber. Daca iti place acest site, te rog sa consideri a dona.<br>Chiar si cea mai mica donatie ma va incuraja sa continui a munci pe Unboxer.tf. Multumesc!",
		swe: "Unboxer.tf gjordes nästan helt av en enda person på sin fritid. Om du gillar webbplatsen, kan du snälla överväga att donera.<br>Även en liten donation kommer att uppmuntra mig att fortsätta arbeta på Unboxer.tf. Tack!",
		fil: "Unboxer.tf ay halos gawa ng isang tao sa bakanteng oras niya. Ipag nagugustuhan mo ang website nito, please isaalang-alang magdonate.<br>Kahit isang maliit na donasyon ay hihimukin ako para magpatuloy nagtratrabaho sa Unboxer.tf. Maraming Salamat!"
    },
    189: {
        eng: "If you choose to donate, your name, Steam profile link and donation amount will appear in the list of donators.<br><b>In your donation message, please include that the donation is for unboxer.tf, as well as your name and Steam profile link.</b><br> You can choose to stay anonymous. Item donations will be displayed as their value in USD.",
        pol: "Jeśli przekażesz darowiznę, twój nick, link do profilu Steam i kwota darowizny zostaną dodane do listy darczyńców.<br><b>W wiadomości dołączonej do darowizny, proszę napisać że darowizna jest dla unboxer.tf oraz wpisz swój nick i link do profilu Steam.</b><br> Możesz także pozostać anonimowym. Darowizny w postaci przedmiotów będą pokazane jako ich wartość w USD.",
        sch: "如果您选择捐赠，您的名字、Steam 个人资料链接与捐赠金额会出现在捐赠列表中。<br>请在您的捐赠附言中说明此款项是捐赠给 Unboxer.tf 的，并附上您的名字与 Steam 个人资料链接。您也可以选择保持匿名。物品捐赠会显示为物品的美元价格。",
        bra: "Se você escolher doar, o seu nome, link do perfil na Steam e o valor da sua doação vão aparecer na lista de doadores. <br><b>Na sua mensagem de doação, por favor inclua que a doação é para unboxer.tf, também inclua o seu nome e link de perfil na Steam.</b><br>Você pode escolher para ficar anônimo. Doações de itens vão ser exibidas em seu preço em dólares americanos.",
        fre: "Si vous choisissez de faire un don, votre nom, profil Steam et le montant apparaitront dans la liste des donneurs.<br><b>Dans le message de votre don, S'il-vous-plaît inclure que le don est pour unboxer.tf, ainsi qu'inclure votre lien de profil Steam.</b><br>Vous pouvez choisir de rester anonyme. Les dons en items seront affichés selon leur valeur en USD.",
        cze: "Pokud se rozhodnete přispět, vaše jméno, odkaz na Steam profil a výše přípěvku budou zobrazeny v seznamu přispěvatelů.<br><b>Ve zprávě příspěvku prosím uvěďte, že příspěvek je pro unboxer.tf, společně s tím uveďtě vaše Steam jméno a odkaz na Steam profil.</b><br> Můžete se rozhodnout zůstat anonymní. Předmětné příspěvky budou zobrazeny spolu s jejich hodnotou v USD.",
        hun: "Ha úgy döntesz adományozol, akkor a neved, Steam profilod linkje és az adomány mennyisége láthatóvá válik az adományozók listáján.<br><b>Kérlek az adományod mellé üzenetben tüntesd fel, hogy az adományt az Unboxer.tf-nek szánod, a nevedet és a Steam profilod linkjét.</b><br>Ha úgy döntesz, maradhatsz anonim is. A tárgyi adományok USD-ben fognak megjelenni az értékük alapján.",
        rom: "Daca alegi sa donezi, numele tau, Link-ul de la contul tau de Steam si suma donatiei va aparea pe lista donatorilor.<br><b>In mesajul donatiei tale, te rog sa incluzi ca donatia este pentru unboxer.tf, deasemenea si numele tau si linkul de la profilul de Steam.</b><br> Poti sa alegi sa ramai necunoscut. Donatiile de obiecte vor avea valoarea aratata in USD.",
		swe: "Om du väljer att donera kommer ditt namn, Steam-profillänk och donationsbelopp att visas i donatorlistan.<br><b>I ditt donationsmeddelande, ange att donationen är för unboxer.tf, liksom din namn och Steam-profillänk.</b><br>Du kan välja att vara anonym. Varudonationer visas som deras värde i USD.",
        fil: "Ipag napili mong magdonate, ang name mo, Steam profile link at halaga ng donasyon ay lilitaw sa listahan ng mga donators.<br><b>Sa iyong donasyon mensahe, please ilagay ang donasyon ay para kay unboxer.tf, at ang iyong name at Steam profile link.</b><br> maari kang pumili na maging anonymous. Aytem donasyon ay ipapakita na halaga nila sa USD."
    },
    190: {
        eng: "Donator List",
        pol: "Lista darczyńców",
        sch: "捐赠列表",
        bra: "Lista de doadores",
        fre: "Liste des donneurs",
        cze: "Seznam přispěvatelů",
        hun: "Adományozók listája",
        rom: "Lista donatorilor",
		swe: "Donations Lista",
		fil: "Listahan ng mga Donator"
    },
    191: {
        eng: "",
    },
    192: {
        eng: 'You can donate with <a href="https://paypal.me/kubikill" target="_blank" rel="noopener">PayPal</a> or <a href="https://steamcommunity.com/tradeoffer/new/?partner=98915182&token=YEmUtMbo" target="_blank" rel="noopener">TF2/Steam items</a>.<br>Brave Browser users can donate using BAT from within the browser.',
        pol: 'Możesz przekazać darowiznę przez <a href="https://paypal.me/kubikill" target="_blank" rel="noopener">PayPal</a> lub <a href="https://steamcommunity.com/tradeoffer/new/?partner=98915182&token=YEmUtMbo" target="_blank" rel="noopener">przedmioty TF2/Steam</a>.<br>Użytkownicy przeglądarki Brave Browser mogą przekazać darowiznę w BAT z poziomu przeglądarki.',
        sch: '您可以通过 <a href="https://paypal.me/kubikill" target="_blank" rel="noopener">PayPal</a> 捐赠现金或向我发送 <a href="https://steamcommunity.com/tradeoffer/new/?partner=98915182&token=YEmUtMbo" target="_blank" rel="noopener">TF2/Steam 物品</a>。<br>Brave 浏览器的用户还可以捐赠浏览器内的 BAT 代币。',
        bra: 'Você pode doar com <a href="https://paypal.me/kubikill" target="_blank" rel="noopener">PayPal</a> ou <a href="https://steamcommunity.com/tradeoffer/new/?partner=98915182&token=YEmUtMbo" target="_blank" rel="noopener">itens da Steam/TF2</a>.<br>Usuários de Brave Browser podem doar usando BAT pelo navegador deles.',
        fre: 'Vous pouvez faire un don via <a href="https://paypal.me/kubikill">PayPal</a> ou avec des <a href="https://steamcommunity.com/tradeoffer/new/?partner=98915182&token=YEmUtMbo">TF2/objets Steam</a>.<br>Brave Browser les usagers peuvent donner en utilisant BAT dans leur fureteur',
        cze: 'Můžete přispět skrze <a href="https://paypal.me/kubikill" target="_blank" rel="noopener">PayPal</a> nebo <a href="https://steamcommunity.com/tradeoffer/new/?partner=98915182&token=YEmUtMbo" target="_blank" rel="noopener">TF2/Steam předměty</a>.<br>Odvážní uživatelé prohlížeče mohou darovat pomocí BAT přímo z prohlížeče.',
        hun: 'Adományozni tudsz itt: <a href="https://paypal.me/kubikill" target="_blank" rel="noopener">PayPal</a> vagy itt: <a href="https://steamcommunity.com/tradeoffer/new/?partner=98915182&token=YEmUtMbo" target="_blank" rel="noopener">TF2/Steam items</a>.<br>Brave Browser felhasználók tudnak adományozni BAT felhasználásával a böngészőn belül.',
        rom: 'Poti dona cu <a href="https://paypal.me/kubikill" target="_blank" rel="noopener">PayPal</a> ori <a href="https://steamcommunity.com/tradeoffer/new/?partner=98915182&token=YEmUtMbo" target="_blank" rel="noopener">TF2/Steam items</a>.<br>Utilizatorii extensiei Brave Browser pot dona utilizand BAT din browser.',
		swe: 'Du kan donera med <a href="https://paypal.me/kubikill" target="_blank" rel="noopener">PayPal</a> eller <a href="https://steamcommunity.com/tradeoffer/new/?partner=98915182&token=YEmUtMbo" target="_blank" rel="noopener">TF2/Steam föremål</a>.<br>Brave Browser-användare kan donera med BAT från webbläsaren.',
		fil: 'Kaya mong magdonate gamit <a href="https://paypal.me/kubikill" target="_blank" rel="noopener">PayPal</a> o <a href="https://steamcommunity.com/tradeoffer/new/?partner=98915182&token=YEmUtMbo" target="_blank" rel="noopener">TF2/Steam items</a>.<br>Brave Browser users ay maaring magdonate gamit BAT sa loob ng browser.'
    },
    193: {
        eng: 'French',
        pol: 'Francuski',
        sch: '法语',
        bra: 'Francês',
        fre: 'Français',
        cze: 'Francouzština',
        hun: 'Francia',
        rom: 'Franceza',
		swe: 'Franska',
		fil: 'Pranses'
    },
    194: {
        eng: 'Spanish',
        pol: 'Hiszpański',
        sch: '西班牙语',
        bra: 'Espanhol',
        cze: 'Španělština',
        hun: 'Spanyol',
        rom: 'Spaniola',
		swe: 'Spanska',
		fil: 'Espanyol'
    },
    195: {
        eng: 'German',
        pol: 'Niemiecki',
        sch: '德语',
        bra: 'Alemão',
        cze: 'Němčina',
        hun: 'Német',
        rom: 'Germana',
		swe: 'Tyska',
		fil: 'Aleman'
    },
    196: {
        eng: 'Brazilian Portuguese',
        pol: 'Brazylijski portugalski',
        sch: '巴西葡萄牙语',
        bra: 'Português do Brasil',
        fre: 'Portugais brésilien',
        cze: 'Brazilská portugalština',
        hun: 'Brazil portugál',
        rom: 'Portugheza Braziliana',
		swe: "Brasiliansk Portugisiska",
		fil: 'Brazilian Portuges'
    },
    197: {
        eng: "View on Steam Market",
        pol: "Wyświetl na Rynku Steam",
        sch: "在 Steam 社区市场查看",
        bra: "Ver na Steam Market",
        fre: "Voir sur le marché Steam",
        cze: "Více na Steam marketu",
        hun: "Megnézés a Steam Piacon",
        rom: "Vezi pe Steam Market",
		swe: "Se på Steam Market",
		fil: "Itingnan sa Steam Market"
    },
    198: {
        eng: "View on Backpack.tf",
        pol: "Wyświetl na Backpack.tf",
        sch: "在 Backpack.tf 查看",
        bra: "Ver em Backpack.tf",
        fre: "Voir sur Backpack.tf",
        cze: "Více na Backpack.tf",
        hun: "Megnézés Backpack.tf-en",
        rom: "Vezi pe Backpack.tf",
		swe: "Se på Backpack.tf",
		fil: "Itingnan sa Backpack.tf"
    },
    199: {
        eng: "View on Marketplace.tf",
        pol: "Wyświetl na Marketplace.tf",
        sch: "在 Marketplace.tf 查看",
        bra: "Ver em Marketplace.tf",
        fre: "Voir sur Marketplace.tf",
        cze: "Více na Marketplace.tf",
        hun: "Megnézés Marketplace.tf-en",
        rom: "Vezi pe Marketplace.tf",
		swe: "Se på Marketplace.tf",
		fil: "Itingna sa Marketplace.tf"
    },
    200: {
        eng: "Enable Unusual Sniper vs. Spy update hats:",
        pol: "Włącz Nietypowe Okazy z aktualizacji Snajper kontra Szpieg:",
        bra: "Ativar efeitos incomuns em chapéus da atualização Sniper vs. Spy:",
        sch: "启用“狙击手 vs. 间谍”更新的稀有帽子",
        fre: "Activer les Inhabituels de l'update Sniper vs. Spy:",
        cze: "Povoluje Unusual předměty ze Sniper vs. Spy updatu:",
        hun: "Mesterlövész kontra Kém Frissítés sapkák engedélyezése:",
        rom: "Porneste Sniper Neobisnuit vs. Spy palarie de actualizare:",
		swe: "Aktivera Ovanliga hattar för Sniper vs. Spy-uppdateringen:",
		fil: "Ibukas Unusual Sniper vs. Spy update hats:"
    },
    201: {
        eng: "Allows the hats added in the Sniper vs. Spy update to be unboxed as Unusuals from crates added before the TF2 January 25th 2013 update (crates #1 - #55).",
        pol: "Ta opcja pozwala na otrzymanie czapek z aktualizacji Snajper kontra Szpieg w jakości Nietypowy Okaz z skrzynek dodanych przed aktualizacją TF2 z 25 stycznia 2013 (skrzynki #1 - #55).",
        bra: "Isso permite que os chapéus adicionados na atualização do Sniper vs. Spy possam ser desencaixotados em qualidade Incomum por caixas adicionadas antes da atualização de TF2 em 25 de Janeiro de 2013 (caixas #1 - #55).",
        sch: "此选项允许从 2013 年 1 月 25 日 TF2 更新之前的箱子（编号为 #1-#55）中开出稀有品质的、在“狙击手 vs. 间谍”更新中添加的帽子。",
        fre: "Cette option permet aux chapeaux ajoutés avec l'update Sniper vs. Spy d'être déballé en qualité Inhabituelle dans les caisses ajoutée avant l'update TF2 du 25 Janvier 2013 (caisses #1 à #55).",
        cze: "Umožňuje rozbalení klobouků přidaných ve Sniper vs. Spy updatu Unusual kvality z krabic přidaných před aktualizací 25. ledna 2013 (krabice #1 - #55)",
        hun: "Engedélyezi, hogy a Mesterlövész kontra Kém Frissítés során hozzáadott sapkákat Rendkívüli minőségbe kibonthasd olyan ládákból, amik a 2013. január 25.-i frissítés előtt lettek hozzáadva (#1-#55-ös szériák)",
        rom: "Permite ca palariile sa fie adaugate in actualizarea Sniper vs Spy pentru a fi deschise ca obiecte Neobisnuite din cutii adaugate inainte de actualizarea TF2 25 Ianuarie 2013 (Cutiile #1 - #55).",
		swe: "Tillåter att hattarna som läggs till i Sniper vs. Spy-uppdateringen att öppnas som Ovanliga från lådor som lagts till före TF2-uppdateringen den 25 januari 2013 (lådor #1 - #55).",
		fil: "Ipayagan ang hats na idinagdad sa Sniper vs. Spy update na mabukas na Unusual sa mga kahon idinagdad bago ang TF2 Enero 25th 2013 update (kahon #1 - #55)."
    },
    202: {
        eng: "Smissmas 2020",
        pol: "Śnięta 2020",
        sch: "圣诞节 2020",
        bra: "Natal de 2020",
        cze: "Vánoce 2020",
        hun: "Galácsony 2020",
        rom: "Craciun 2020",
		swe: "Smissmas 2020"
    },
    203: {
        eng: "Smissmas 2019",
        pol: "Śnięta 2019",
        sch: "圣诞节 2019",
        bra: "Natal de 2019",
        cze: "Vánoce 2019",
        hun: "Galácsony 2019",
        rom: "Craciun 2019",
		swe: "Smissmas 2019"
    },
    204: {
        eng: "Search",
        pol: "Szukaj",
        cze: "Vyhledávání",
        hun: "Keresés",
        rom: "Cauta",
		swe: "Sök",
		fil: "I-search"
    },
    205: {
        eng: "Type to search",
        pol: "Pisz, aby wyszukać",
        cze: "Piš pro vyhledávání",
        hun: "Kezdj el gépelni a kereséshez",
        rom: "Scrie ca sa cauti",
		swe: "Skriv för att söka",
		fil: "I-type para i-search"
    },
    206: {
        eng: 'Czech',
        pol: 'Czeski',
        cze: 'Čeština',
        hun: 'Cseh',
        rom: 'Ceha',
		swe: 'Tjeckiska'
    },
    207: {
        eng: 'Hungarian',
        pol: "Węgierski",
        hun: 'Magyar',
        rom: 'Maghiara',
		swe: 'Ungerska',
		fil: 'Hanggaryan'
    },
	208: {
        eng: 'Romanian',
        pol: "Rumuński",
        rom: 'Romana',
		swe: 'Rumänska',
		fil: 'Rumano'
    },
	209: {
		eng: "Swedish",
		pol: "Szwecki",
		swe: "Svenska",
		fil: "Suweko"
	},
	210: {
		eng: "Filipino",
		pol: "Filipiński"
	},
    211: {
        eng: "Scream Fortress XIII (2021)",
        sch: "尖叫要塞 XIII（2021）",
        rom: "Fortareata Urlatoare XIII (2021)",
		swe: "Skrik Fortress XIII (2021)"
    },
    212: {
        eng: "Smissmas 2021",
        pol: "Śnięta 2021",
        sch: "圣诞节 2021",
        bra: "Natal de 2021",
        cze: "Vánoce 2021",
        hun: "Galácsony 2021",
        rom: "Craciun 2021",
		swe: "Smissmas 2021"
    },
}